import { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import api from '../../providers/api'
import { useProducts } from '../../providers/contextProducts'
import './style.scss'
import ProductCardComponent from '../../components/ProductCardComponent'
import { Link } from 'react-router-dom'
import Loader from '../../utils/loader'

export const PageProducts = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [courses, setCourses] = useState([])
  const [ebooks, setEbooks] = useState([])

  const { coursesContext, ebooksContext } = useProducts()
  const { coursesValue, setCoursesValue } = coursesContext
  const { ebooksValue, setEbooksValue } = ebooksContext

  useEffect(() => {
    if (coursesValue === null || ebooksValue === null) {
      api
        .get(`products/ebooks`)
        .then((response) => {
          setEbooks(response.data.rows)
          setEbooksValue(response.data.rows)
        })
        .catch((err) => {})
        .finally(() => setIsLoading(false))
      setIsLoading(true)

      api
        .get(`products/courses`)
        .then((response) => {
          setCourses(response.data.rows)
          setCoursesValue(response.data.rows)
        })
        .catch((err) => {})
        .finally(() => setIsLoading(false))
    } else {
      setEbooks(ebooksValue)
      setCourses(coursesValue)
    }
  }, [])

  return (
    <>
      <div className="wrap-page-content">
        <div id="product-page">
          <Container className="products page-product-content">
            {courses.length > 0 && (
              <>
                <h2>Meus Cursos</h2>
                <div className="list-products">
                  {courses.map((product) => (
                    <div key={product.uuid} className="item">
                      <Link
                        to={`/cursos/${product.slug}/${product.uuid}`}
                        className="link-course"
                      >
                        <ProductCardComponent course={product} />
                      </Link>
                    </div>
                  ))}
                </div>
              </>
            )}
            {ebooks.length > 0 && (
              <>
                <h2 className="mt-5">Meus Ebooks</h2>
                <div className="list-products">
                  {ebooks.map((product) => (
                    <div key={product.uuid} className="item">
                      <Link
                        to={`/ebooks/${product.slug}/${product.uuid}`}
                        className="link-course"
                      >
                        <ProductCardComponent course={product} />
                      </Link>
                    </div>
                  ))}
                </div>
              </>
            )}
            {courses.length === 0 && ebooks.length === 0 && (
              <div className="no-content">
                <h3>Não há conteúdo para mostrar</h3>
              </div>
            )}
          </Container>
        </div>
      </div>
    </>
  )
}
