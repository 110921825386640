import { Link } from 'react-router-dom'
import ProductCardComponent from '../../components/ProductCardComponent'
import './style.scss'

function PageCourses({ courses, title }) {
  return (
    <>
      {courses && courses.length > 0 && (
        <div className="products container">
          <h2>{title}</h2>
          <div className="list-products">
            {courses.map((product) => (
              <Link
                to={`/cursos/${product.slug}/${product.uuid}`}
                className="link-course"
                key={product.uuid}
              >
                <ProductCardComponent course={product} />
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default PageCourses
