import React from 'react'
import { useParams } from 'react-router-dom'
import api from '../../providers/api'
import './style.scss'

const Attachments = ({ files, downloading, setDownloading }) => {
  const { uuidCourse, uuidLesson } = useParams()

  const downloadAttachment = (item) => {
    setDownloading(true)
    api
      .get(`products/attachment/download/${uuidCourse}/${item.uuid}`, {
        responseType: 'blob'
      })
      .then((blob) => {
        const filename = item.original_name

        const url = window.URL.createObjectURL(blob.data)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)

        document.body.appendChild(link)

        link.click()

        link.parentNode.removeChild(link)
      })
      .catch((err) => {
        console.error(err.response)
      })
      .finally(() => setDownloading(false))
  }

  return (
    <>
      <div className="archives">
        {files.map((item, index) => (
          <div
            className="archive"
            onClick={() => {
              downloadAttachment(item)
            }}
            key={index}
          >
            <div className="title">{item.original_name}</div>
            <div className="bottom-bar">
              <div className="type">
                <i class="bx bx-file-blank"></i>
                <div className="arquive-type">{item.file_extension}</div>
              </div>
              <div className="file-size">{item.file_size}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default Attachments
