import ReactPlayer from 'react-player'

const VideoPlayer = ({ url, setVideoEnded, setVideoProgress }) => {
  return (
    <ReactPlayer
      url={url}
      controls
      onEnded={() => setVideoEnded(true)}
      onProgress={({ played }) => setVideoProgress(played)}
      width={'100%'}
      height={'100%'}
    />
  )
}

export default VideoPlayer
