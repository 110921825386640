import React, { useState } from 'react'

const PageCancel = () => {
  const [disabled, setDisabled] = useState(true)
  const [valid, setValid] = useState(false)

  return (
    <>
      <div className="bg-grey">
        <div className="page-content-inner uk-light  mb-2">
          <div className="p-3">
            <h1> Cancelar Adesão </h1>
            <p>Descontente com o conteúdo adquirido? Entre em contato.</p>
          </div>
        </div>
      </div>
      <div className="page-content-inner">
        <h3 className="uk-margin-medium-top " id="Text-wrapping">
          Processar Cancelamento de [nome_curso]
        </h3>
        <form action="">
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="">Conversou com o suporte do autor?</label>
                <select
                  className="form-control"
                  name=""
                  id=""
                  onChange={() => {
                    setDisabled(true)
                  }}
                >
                  <option value="0">Não</option>
                  <option value="1">Sim</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="">Motivo do Cancelamento</label>
                <textarea placeholder="Seja o mais específico possível..."></textarea>
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-danger">
                  Solicitar Cancelamento
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <p>
                <b>
                  Recomendamos que primeiramente entre em contato com o suporte
                  do autor do curso.
                </b>
              </p>
              <p>
                Caso mesmo assim deseja prosseguir com o cancelamento, siga os
                passos abaixo.
              </p>
              <p>
                Para que possamos dar procedimento no cancelamento deste curso,
                você deve preencher o formulário com o máximo de informações
                possível.
              </p>
              <p>
                Dessa maneira conseguiremos analisar o seu caso com mais
                atenção.
              </p>
              <p>
                Dúvidas? Entre em contato em{' '}
                <a href="https://b4you.com.br">b4you.com.br</a>
              </p>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default PageCancel
