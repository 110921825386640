import { useState, useEffect, useMemo } from 'react'
import { Row, Col, Modal, Button, Form, Alert } from 'react-bootstrap'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import api from '../../providers/api'
import { useStudent } from '../../providers/contextStudent'
import { validateEmail } from '../../components/functions'
import logo from '../../assets/images/sixbase/logo-horizontal.png'
import Footer from '../../components/Footer'

const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => {
    const params = new URLSearchParams(search)
    const result = {}
    for (const [key, value] of params.entries()) {
      result[key] = value
    }
    return result
  }, [search])
}

function PageLogin() {
  const [show, setShow] = useState(false)
  const [fieldEmail, setFieldEmail] = useState('')
  const [fieldPassword, setFieldPassword] = useState('')
  const [forgotEmail, setForgotEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isLoading2, setIsLoading2] = useState(false)
  const history = useHistory()
  const { setStudent } = useStudent()
  const [loginReturn, setLoginReturn] = useState(null)
  const [forgotReturn, setForgotReturn] = useState(null)
  const { token } = useParams()
  const { producer_id = null } = useQuery()

  useEffect(() => {
    if (token) {
      api
        .get(`/auth/validate/login/${token}?producer_id=${producer_id}`)
        .then(() => {
          window.location.href = window.location.origin
        })
    }
  }, [])

  useEffect(() => {
    api
      .get('auth/me')
      .then((response) => {
        if (response.data !== null) {
          setStudent(response.data)
        }
        setIsLoading(false)
        history.push('/')
      })
      .catch((err) => {
        setIsLoading(false)
      })
  }, [])

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsLoading(true)
    setLoginReturn(null)

    const fields = {
      email: fieldEmail,
      password: fieldPassword
    }
    api
      .post('auth/login', fields)
      .then((response) => {
        setStudent(response.data)
        history.push('/')
      })
      .catch((err) => {
        const { response } = err
        if (response && response.status === 429) {
          return setLoginReturn(
            `Número de tentativas excedido, tente novamente em ${err.response.data.seconds} segundos`
          )
        }
        return setLoginReturn(
          `${response.data.message || 'Erro, tente novamente'}`
        )
      })
      .finally(() => setIsLoading(false))
  }

  const formValid = () => {
    if (fieldEmail.length === 0) {
      return false
    }
    if (fieldPassword.length === 0) {
      return false
    }

    return true
  }

  const forgotPassword = () => {
    const fields = {
      email: forgotEmail
    }
    setForgotReturn(null)
    setIsLoading2(true)
    api
      .post('auth/forgotpassword', fields)
      .then((response) => {
        setIsLoading2(false)

        if (response.data.success) {
          setForgotReturn('success')
          setForgotEmail('')
        } else {
          setForgotReturn('not-found')
        }
      })
      .catch((err) => {
        setIsLoading2(false)

        setForgotReturn('error')
      })
  }

  return (
    <div className="access-bg">
      <Modal
        show={show}
        centered
        onHide={handleClose}
        className="modal-access"
        id="modal-style"
      >
        <Modal.Header closeButton>
          <h5 className="mb-0">Recuperar Senha</h5>
        </Modal.Header>
        <Modal.Body>
          {forgotReturn === 'success' && (
            <Alert variant="success">
              E-mail de recuperação enviado com sucesso.
            </Alert>
          )}
          {forgotReturn === 'not-found' && (
            <Alert variant="danger">
              Não encontramos um cadastro com este e-mail.
            </Alert>
          )}
          {forgotReturn === 'error' && (
            <Alert variant="danger">
              Erro, se o problema persistir entre em contato com o suporte.
            </Alert>
          )}

          <p>
            Se você esqueceu a sua senha de acesso, informe o seu e-mail de
            cadastro abaixo para que possamos lhe reenviar um link para criar
            uma nova senha para sua conta.
          </p>
          <Row>
            <Col md={12}>
              <Form.Control
                placeholder="Seu e-mail"
                className="mt-3 forgot-input"
                onChange={(e) => {
                  setForgotEmail(e.target.value)
                }}
              />
            </Col>
            <Col>
              <Button
                variant={isLoading2 ? 'light' : 'primary'}
                disabled={!validateEmail(forgotEmail) || isLoading2}
                onClick={forgotPassword}
                className="forgot-mail"
              >
                {!isLoading2 ? (
                  'Recuperar Senha'
                ) : (
                  <i className="bx bx-loader-alt bx-spin"></i>
                )}
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <div className="wrap-container">
        {/*  <img className="wrap-right-image" src={loginLeftImage} /> */}
        <div className="access-card">
          <div className="top">
            <img src={logo} alt="Logotipo área de membros" />
            <h2>Área de Membros</h2>
            <span>Faça login para acessar seus cursos</span>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="uk-form-group mt-3">
              {loginReturn && <Alert variant="danger">{loginReturn}</Alert>}
            </div>

            <Row className="input-list">
              <Col className="col-12">
                <Form.Group>
                  <label>Email</label>
                  <Form.Control
                    type="email"
                    placeholder="Digite o seu email..."
                    onChange={(e) => {
                      setFieldEmail(e.target.value)
                    }}
                  />
                </Form.Group>
              </Col>

              <Col className="col-12">
                <Form.Group>
                  <label>Senha</label>
                  <Form.Control
                    type="password"
                    placeholder="••••••••"
                    onChange={(e) => {
                      setFieldPassword(e.target.value)
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <a className="forgot-pass" onClick={handleShow}>
              Esqueci minha senha
            </a>

            <Row>
              <Col className="col-12">
                <Button
                  type="submit"
                  variant="default"
                  className="btn btn-default"
                  disabled={!formValid() || isLoading}
                >
                  {!isLoading ? (
                    <>
                      <span>Acessar</span>
                      <i className="bx bx-right-arrow-alt"></i>
                    </>
                  ) : (
                    <i className="bx bx-loader-alt bx-spin"></i>
                  )}
                </Button>
              </Col>
            </Row>
          </form>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default PageLogin
