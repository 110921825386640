import React from 'react'
import './style.scss'

const Loader = () => (
  <div id="loader">
    <div id="loading-app">
      <svg
        width="397"
        height="503"
        viewBox="0 0 397 503"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M299.894 4.2876C297.472 6.6299 287.468 16.1182 277.662 25.408C267.856 34.6979 258.129 43.9083 256.065 45.8933C254.001 47.8386 248.562 53.0393 243.956 57.3666C239.391 61.6939 234.19 66.6167 232.443 68.2841C230.697 69.9515 225.536 74.8743 220.93 79.2016C216.365 83.529 211.164 88.4518 209.417 90.1192C207.671 91.7866 202.51 96.7094 197.904 101.037C193.339 105.404 188.138 110.287 186.391 111.994C184.645 113.661 175.99 121.879 167.137 130.216C158.284 138.593 149.629 146.811 147.882 148.478C146.136 150.146 140.975 155.068 136.369 159.396C131.804 163.723 126.603 168.646 124.856 170.313C123.11 171.981 117.949 176.904 113.343 181.231C108.778 185.558 103.577 190.481 101.83 192.148C100.084 193.816 94.9227 198.739 90.3175 203.066C85.752 207.433 80.5513 212.316 78.8045 214.023C77.0577 215.691 68.4031 223.908 59.55 232.245C50.6969 240.622 42.0423 248.84 40.2955 250.507C38.5487 252.175 33.3877 257.098 28.7825 261.425C24.217 265.752 19.0163 270.675 17.2695 272.343C15.5227 274.01 10.9175 278.377 7.0666 282.069L0 288.739V349.797V410.896H37.318H74.636V366.074V321.253L93.4141 303.467C103.696 293.661 113.78 284.133 115.725 282.268C117.71 280.362 123.07 275.28 127.635 270.953C132.241 266.626 137.402 261.703 139.148 260.036C140.895 258.368 146.096 253.445 150.661 249.118C155.267 244.791 160.428 239.868 162.174 238.161C163.921 236.493 177.777 223.392 192.942 209.021C208.107 194.649 221.963 181.509 223.709 179.841C225.456 178.174 230.657 173.251 235.222 168.924C239.828 164.597 244.989 159.674 246.735 158.006C248.482 156.339 253.762 151.337 258.447 146.89C263.132 142.444 267.538 138.236 268.253 137.6C268.967 136.926 277.463 128.866 287.19 119.696L304.817 103.022L304.856 51.491C304.896 23.1848 304.777 -1.20029e-05 304.618 -1.20029e-05C304.42 0.0396881 302.315 1.94529 299.894 4.2876Z"
          fill="#5BEBD4"
        />
        <path
          d="M230.538 244.036C230.379 244.155 230.26 264.879 230.26 290.088V335.863H184.208H138.156V373.379V410.896H184.208H230.26V456.948V503H267.578H304.896V456.948V410.896H350.948H397V373.379V335.863H350.948H304.896V289.811V243.758H267.856C247.45 243.758 230.657 243.878 230.538 244.036Z"
          fill="#5BEBD4"
        />
      </svg>
    </div>
  </div>
)

export default Loader
