import ImgAvatar from '../../assets/images/avatars/home-profile.jpg'
import './style.scss'
import { Row, Col, Container, Form } from 'react-bootstrap'
import { useStudent } from '../../providers/contextStudent'
import { useProducts } from '../../providers/contextProducts'
import { useState, useRef, useEffect } from 'react'
import api from '../../providers/api'
import { useForm, Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'
import axios from 'axios'
import jsonListOfBanks from '../../json/jsonListOfBanks'
import { BankAccountForm } from './BankAccountForm'
import AvatarImage from '../../components/AvatarImage'
import { notify } from '../../components/functions'
import Dropzone from 'react-dropzone'
import imageCloud from '../../assets/images/feather-cloud.svg'
import Loader from '../../utils/loader'

function PageProfile() {
  const { student, setStudent } = useStudent()

  const preloadedValues = student
  const {
    register,
    setValue,
    handleSubmit,
    errors,
    control,
    formState,
    reset,
    getValues
  } = useForm({ mode: 'onChange' })
  const { isValid } = formState

  const [isUploading, setIsUploading] = useState(false)
  const [requesting, setRequesting] = useState(false)

  const [errorSize, setErrorSize] = useState(false)

  const [image, setImage] = useState(null)

  const maxFileSize = 2097152 // 2mb

  const onImageChange = (event) => {
    // /student/avatar

    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0]
      setImage(img)
    }
  }

  const handleDrop = (acceptedFile, rejectedFiles) => {
    setErrorSize(false)
    rejectedFiles.map((item) => {
      if (!item[0]) {
        setErrorSize(true)
        notify({
          message: 'Tamanho máximo permitido de 2mb por arquivo.',
          type: 'error'
        })
      }
    })
    setImage(acceptedFile[0])
  }

  const fetchZipCode = (e) => {
    const cep = e.target.value.replace(/\D/g, '')

    if (cep.length === 8) {
      axios
        .get(`https://viacep.com.br/ws/${cep}/json`)
        .then((response) => {
          const address = response.data

          setValue('street', address.logradouro)
          setValue('number', '')
          setValue('complement', '')
          setValue('neighbourhood', address.bairro)
          setValue('city', address.localidade)
          setValue('state', address.uf)
        })
        .catch((err) => {
          // console.log(err.response);
        })
    } else if (cep.length === 0) {
      setValue('street', '')
      setValue('number', '')
      setValue('complement', '')
      setValue('neighbourhood', '')
      setValue('city', '')
      setValue('state', '')
    }
  }

  // preload input fields with existing students data
  useEffect(() => {
    api
      .get('/student/profile')
      .then((response) => {
        const { data } = response

        // setDefaultData(data);

        reset(data)
        // for (let item in data) {
        //   if (item != 'address') {
        //     setValue(item, data[item]);
        //   }
        //   // else {
        //   //   // if is address
        //   //   for (let add in data.address) {
        //   //     setValue(add, data.address[add]);
        //   //   }
        //   // }
        // }
      })
      .catch(() => {})
  }, [])

  useEffect(() => {
    if (image) {
      const formData = new FormData()

      formData.append('profile_picture', image)

      setIsUploading(true)

      api
        .put('/student/avatar', formData)
        .then((response) => {
          setIsUploading(false)
          setStudent({
            ...student,
            profile_picture: response.data.profile_picture
          })
        })
        .catch((err) => {
          console.error(err.response)
          setIsUploading(false)
        })
    }
  }, [image])

  const onSubmit = (data) => {
    setRequesting(true)

    api
      .put('/student/profile', data)
      .then((response) => {
        notify({ message: 'Dados salvos com sucesso', type: 'success' })
        setStudent(response.data)
        setRequesting(false)
      })
      .catch((err) => {
        notify({ message: 'Falha ao salvar os dados', type: 'error' })
        console.error(err.response)
        setRequesting(false)
      })
  }

  return (
    <>
      <div className="wrap-page-content">
        <section id="page-profile">
          <Container className="page-profile-content">
            <h2 className="title">
              <AvatarImage src={student.profile_picture} />
              <div>{student.full_name}</div>
            </h2>

            <Row className="mt-5 row-info">
              <Col md={6}>
                <form action="" onSubmit={handleSubmit(onSubmit)}>
                  <div className="sub-title">
                    <i className="bx bx-user"></i>
                    <h2>Informações Pessoais</h2>
                  </div>
                  <Row className="input-list">
                    <Col md={12}>
                      <Form.Group>
                        <input
                          name="full_name"
                          type="text"
                          placeholder="Nome completo"
                          className={errors.full_name}
                          ref={register({
                            required: true
                          })}
                        />
                        <div className="form-error">
                          {errors.full_name && (
                            <span>{errors.full_name.message}</span>
                          )}
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group>
                        <InputMask
                          name="whatsapp"
                          ref={register({
                            required: true,
                            validate: (value) => {
                              const numbers = value.replace(/\D/g, '')
                              if (numbers.length >= 11) {
                                return true
                              }
                              return false
                            }
                          })}
                          className={
                            !errors.whatsapp
                              ? 'uk-input'
                              : 'uk-input is-invalid'
                          }
                          placeholder="xx xxxxx xxxx"
                          type="text"
                          mask="(99) 99999-9999"
                        />
                        <div className="form-error">
                          {errors.whatsapp && (
                            <span>{errors.whatsapp.message}</span>
                          )}
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Dropzone
                        onDrop={(acceptedFile, rejectedFiles) => {
                          return handleDrop(acceptedFile, rejectedFiles)
                        }}
                        accept="image/jpeg, image/png,image/gif"
                        maxSize={maxFileSize}
                        multiple={false}
                      >
                        {({
                          getRootProps,
                          getInputProps,
                          isDragActive,
                          isDragReject
                        }) => (
                          <div
                            {...getRootProps()}
                            isDragActive={isDragActive}
                            isDragReject={isDragReject}
                          >
                            <div
                              className={
                                isDragActive
                                  ? 'dragActive input-image'
                                  : errorSize
                                  ? 'input-image input-image-error'
                                  : 'input-image'
                              }
                            >
                              {isDragActive && (
                                <div className="dragActiveMessage">
                                  <i className="bx bx-upload"></i>
                                  Solte seu arquivo aqui
                                </div>
                              )}
                              <div className="left">
                                <img src={imageCloud} />
                              </div>
                              <div className="right">
                                <h4>Enviar imagem de perfil</h4>
                                <p>
                                  JPG ou PNG, tamanho do arquivo não superior a
                                  2 MB
                                </p>
                                <div
                                  className={
                                    errorSize
                                      ? 'input-default btn btn-outline-danger'
                                      : 'input-default btn btn-outline-primary'
                                  }
                                >
                                  Selecionar arquivo
                                </div>
                                <input {...getInputProps()} />
                              </div>
                              <input {...getInputProps()} />
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Col>

                    <Col md={12}>
                      <button
                        type="submit"
                        className="btn btn-default w-100 md-flex"
                        disabled={requesting || !isValid}
                      >
                        {requesting ? 'Salvando...' : 'Salvar informações'}
                      </button>
                    </Col>
                  </Row>
                </form>
              </Col>
              <Col md={6}>
                <BankAccountForm />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  )
}

export default PageProfile
