import { Row, Col, Container } from 'react-bootstrap'
import { Link, useHistory, useParams, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import api from '../../providers/api'
import Author from '../../components/Author'
import ProductCardComponent from '../../components/ProductCardComponent'
import Loader from '../../utils/loader'
import './style.scss'
import { generateColorVariations } from '../../utils/colors/colors'
import WhatsApp from './whatsapp'
import Header from '../../components/Header'
import HeaderProduct from '../../components/HeaderProduct'

const injectJivo = ({ token }) => {
  const head = document.getElementsByTagName('head')[0]
  const script = document.createElement('script')
  script.src = `//code.jivosite.com/widget/${token}`
  script.id = 'jivoPlugin'
  script.async = true
  head.appendChild(script)
}
const onBackButtonEvent = (e) => {
  e.preventDefault()
  const { pathname } = window.location
  console.log('path name ', pathname)
  if (pathname === '/') {
    window.location.reload()
  }
}

function PageCourseSingle() {
  const { uuid } = useParams()
  const [activeCourse, setActiveCourse] = useState(null)
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    api
      .get(`/products/${uuid}/membership-plugins`)
      .then((r) => {
        const jivoPlugin = r.data.plugins.find(
          (p) => p.type.key === 'jivo-chat'
        )
        const whatsPlugin = r.data.plugins.find(
          (p) => p.type.key === 'whatsapp'
        )
        if (jivoPlugin) {
          injectJivo(jivoPlugin.settings)
          window.addEventListener('popstate', onBackButtonEvent)
        }
        if (whatsPlugin) {
          setPhone(whatsPlugin.settings.phone)
          setMessage(whatsPlugin.settings.message)
        }
      })
      .catch(() => {})

    api
      .get(`products/${uuid}`)
      .then((response) => {
        setActiveCourse(response.data)
      })
      .catch((err) => {})

    api
      .get(`products/template/${uuid}`)
      .then((response) => {
        generateColorVariations(
          response.data.hex_color_membership_primary,
          'primary'
        )
        generateColorVariations(
          response.data.hex_color_membership_secondary,
          'secondary'
        )
      })
      .catch((err) => {})
  }, [])

  return (
    <>
      <section id="page-course">
        <main className="page-content page-content-courses">
          {activeCourse ? (
            <>
              <Container className="course-info">
                <Row>
                  <Col md={12} sm={12}>
                    {!activeCourse.anchor_view && <h2>Módulos</h2>}
                    {activeCourse?.anchor_view ? (
                      <>
                        {activeCourse?.anchors?.map((anchor) => {
                          return (
                            <>
                              <h2>{anchor.label}</h2>
                              <div className="list-products">
                                {anchor.modules.length === 0 && (
                                  <div className="course-without-content">
                                    Sem conteúdo
                                  </div>
                                )}
                                {anchor.modules.map((module) => {
                                  const { lessons } = module
                                  const [firstLesson = null] = lessons
                                  if (!module.released) {
                                    return (
                                      <ProductCardComponent
                                        key={module.uuid}
                                        activeCourse={module}
                                        releaseDate={module.release_date}
                                        isAnchor={activeCourse.anchor_view}
                                        style={{
                                          opacity: '0.8',
                                          cursor: 'not-allowed',
                                          filter: 'grayscale(1)'
                                        }}
                                      />
                                    )
                                  }
                                  if (!firstLesson) {
                                    return (
                                      <ProductCardComponent
                                        key={module.uuid}
                                        activeCourse={module}
                                        isAnchor={activeCourse.anchor_view}
                                        style={{
                                          opacity: '0.8',
                                          cursor: 'not-allowed',
                                          filter: 'grayscale(1)'
                                        }}
                                      />
                                    )
                                  }
                                  return (
                                    <Link
                                      key={firstLesson.uuid}
                                      to={`/aprenda/${activeCourse.slug}/${uuid}/${firstLesson.uuid}`}
                                      className={
                                        firstLesson.history.done ? 'done' : null
                                      }
                                    >
                                      <ProductCardComponent
                                        activeCourse={module}
                                        isAnchor={activeCourse.anchor_view}
                                      />
                                    </Link>
                                  )
                                })}
                              </div>
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <div className="list-products">
                        {activeCourse.modules.length > 0 ? (
                          <>
                            {activeCourse.modules.map((module) => {
                              const { lessons } = module
                              const [firstLesson = null] = lessons
                              if (!module.released) {
                                return (
                                  <ProductCardComponent
                                    key={module.uuid}
                                    activeCourse={module}
                                    releaseDate={module.release_date}
                                    style={{
                                      opacity: '0.8',
                                      cursor: 'not-allowed',
                                      filter: 'grayscale(1)'
                                    }}
                                  />
                                )
                              }
                              if (!firstLesson) {
                                return (
                                  <ProductCardComponent
                                    key={module.uuid}
                                    activeCourse={module}
                                    style={{
                                      opacity: '0.8',
                                      cursor: 'not-allowed',
                                      filter: 'grayscale(1)'
                                    }}
                                  />
                                )
                              }
                              return (
                                <Link
                                  key={firstLesson.uuid}
                                  to={`/aprenda/${activeCourse.slug}/${uuid}/${firstLesson.uuid}`}
                                  className={
                                    firstLesson.history.done ? 'done' : null
                                  }
                                >
                                  <ProductCardComponent activeCourse={module} />
                                </Link>
                              )
                            })}
                          </>
                        ) : (
                          <div className="course-without-content">
                            Curso sem conteúdo
                          </div>
                        )}
                      </div>
                    )}
                  </Col>
                  <Col lg={8} md={8} className="mt-4">
                    {activeCourse.description && (
                      <>
                        <h2 className="mt-4">Descrição</h2>
                        <div
                          className="uk-card-default uk-card-body"
                          style={{ minWidth: '340px' }}
                        >
                          {activeCourse.description}
                        </div>
                      </>
                    )}
                    {activeCourse.producer && (
                      <>
                        <h2 className="mt-4">Suporte</h2>
                        <div className="uk-card-default uk-card-body mt-4">
                          <Row className="author-info">
                            <Col>
                              <Author
                                producer={activeCourse.producer}
                                className="author-name"
                              />
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
            </>
          ) : (
            <>
              <Loader />
            </>
          )}
          <WhatsApp phone={phone} message={message} />
        </main>
      </section>
    </>
  )
}

export default PageCourseSingle
