import React from 'react'
import AvatarImage from './AvatarImage'

const Author = ({ producer }) => (
  <div className="author">
    <AvatarImage src={producer.profile_picture} className="avatar" />
    <div>
      <span className="nickname">{producer.nickname || 'Desconhecido'}</span>
      <div className="contact">
        <span className="email">
          <b>E-mail:</b>
          <>{` ${producer.support_email || 'Não informado'}`}</>
        </span>
        <span className="whatsapp">
          <b>WhatsApp:</b>
          <>{` ${producer.support_whatsapp || 'Não informado'}`}</>
        </span>
      </div>
    </div>
  </div>
)

export default Author
