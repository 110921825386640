// 20210127161123
// https://raw.githubusercontent.com/guibranco/BancosBrasileiros/master/bancos.json

const jsonListOfBanks = [
  {
    value: '001',
    label: 'BANCO DO BRASIL',
    Document: '00.000.000/0001-91',
    CreatedAt: '2017-04-19 15:52:42.400',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '341',
    label: 'ITAÚ',
    CreatedAt: '2017-04-19 15:53:59.107',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '104',
    label: 'CAIXA ECONÔMICA FEDERAL',
    CreatedAt: '2017-04-19 15:54:41.200',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '033',
    label: 'BANCO SANTANDER S.A.',
    CreatedAt: '2017-04-19 15:55:59.197',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '070',
    label: 'BRB - BANCO DE BRASÍLIA',
    CreatedAt: '2017-04-19 15:58:17.387',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '077',
    label: 'BANCO INTER',
    Document: '00.416.968/0001-01',
    CreatedAt: '2017-04-19 15:58:39.887',
    UpdatedAt: '2020-04-29 11:08:00.000',
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '237',
    label: 'BRADESCO',
    CreatedAt: '2017-04-19 16:00:31.407',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '745',
    label: 'CITIBANK',
    CreatedAt: '2017-04-19 16:01:50.353',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '422',
    label: 'BANCO SAFRA',
    CreatedAt: '2017-04-19 16:04:20.790',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '399',
    label: 'BANCO HSBC',
    CreatedAt: '2017-04-19 16:05:20.353',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '756',
    label: 'BANCOOB',
    CreatedAt: '2017-08-01 22:29:11.827',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '212',
    label: 'BANCO ORIGINAL',
    CreatedAt: '2017-10-11 20:33:06.803',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '002',
    label: 'BANCO CENTRAL DO BRASIL',
    CreatedAt: '2018-01-29 15:12:24.270',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '003',
    label: 'BANCO DA AMAZONIA S.A',
    CreatedAt: '2018-01-29 15:12:24.270',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '004',
    label: 'BANCO DO NORDESTE DO BRASIL S.A',
    CreatedAt: '2018-01-29 15:12:24.270',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '007',
    label: 'BANCO NAC DESENV. ECO. SOCIAL S.A',
    CreatedAt: '2018-01-29 15:12:24.270',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '008',
    label: 'BANCO MERIDIONAL DO BRASIL',
    CreatedAt: '2018-01-29 15:12:24.270',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '020',
    label: 'BANCO DO ESTADO DE ALAGOAS S.A',
    CreatedAt: '2018-01-29 15:12:24.270',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '021',
    label: 'BANCO DO ESTADO DO ESPIRITO SANTO S.A',
    CreatedAt: '2018-01-29 15:12:24.270',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '022',
    label: 'BANCO DE CREDITO REAL DE MINAS GERAIS SA',
    CreatedAt: '2018-01-29 15:12:24.273',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '024',
    label: 'BANCO DO ESTADO DE PERNAMBUCO',
    CreatedAt: '2018-01-29 15:12:24.273',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '025',
    label: 'BANCO ALFA S/A',
    CreatedAt: '2018-01-29 15:12:24.273',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '026',
    label: 'BANCO DO ESTADO DO ACRE S.A',
    CreatedAt: '2018-01-29 15:12:24.273',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '027',
    label: 'BANCO DO ESTADO DE SANTA CATARINA S.A',
    CreatedAt: '2018-01-29 15:12:24.273',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '028',
    label: 'BANCO DO ESTADO DA BAHIA S.A',
    CreatedAt: '2018-01-29 15:12:24.277',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '029',
    label: 'BANCO DO ESTADO DO RIO DE JANEIRO S.A',
    CreatedAt: '2018-01-29 15:12:24.277',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '030',
    label: 'BANCO DO ESTADO DA PARAIBA S.A',
    CreatedAt: '2018-01-29 15:12:24.277',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '031',
    label: 'BANCO DO ESTADO DE GOIAS S.A',
    CreatedAt: '2018-01-29 15:12:24.277',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '032',
    label: 'BANCO DO ESTADO DO MATO GROSSO S.A.',
    CreatedAt: '2018-01-29 15:12:24.277',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '034',
    label: 'BANCO DO ESADO DO AMAZONAS S.A',
    CreatedAt: '2018-01-29 15:12:24.280',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '035',
    label: 'BANCO DO ESTADO DO CEARA S.A',
    CreatedAt: '2018-01-29 15:12:24.280',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '036',
    label: 'BANCO DO ESTADO DO MARANHAO S.A',
    CreatedAt: '2018-01-29 15:12:24.280',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '037',
    label: 'BANCO DO ESTADO DO PARA S.A',
    CreatedAt: '2018-01-29 15:12:24.280',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '038',
    label: 'BANCO DO ESTADO DO PARANA S.A',
    CreatedAt: '2018-01-29 15:12:24.280',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '039',
    label: 'BANCO DO ESTADO DO PIAUI S.A',
    CreatedAt: '2018-01-29 15:12:24.280',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '041',
    label: 'BANCO DO ESTADO DO RIO GRANDE DO SUL S.A',
    CreatedAt: '2018-01-29 15:12:24.283',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '047',
    label: 'BANCO DO ESTADO DE SERGIPE S.A',
    CreatedAt: '2018-01-29 15:12:24.283',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '048',
    label: 'BANCO DO ESTADO DE MINAS GERAIS S.A',
    CreatedAt: '2018-01-29 15:12:24.283',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '059',
    label: 'BANCO DO ESTADO DE RONDONIA S.A',
    CreatedAt: '2018-01-29 15:12:24.283',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '106',
    label: 'BANCO ITABANCO S.A.',
    CreatedAt: '2018-01-29 15:12:24.287',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '107',
    label: 'BANCO BBM S.A',
    CreatedAt: '2018-01-29 15:12:24.287',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '109',
    label: 'BANCO CREDIBANCO S.A',
    CreatedAt: '2018-01-29 15:12:24.290',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '116',
    label: 'BANCO B.N.L DO BRASIL S.A',
    CreatedAt: '2018-01-29 15:12:24.290',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '148',
    label: 'MULTI BANCO S.A',
    CreatedAt: '2018-01-29 15:12:24.290',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '151',
    label: 'CAIXA ECONOMICA DO ESTADO DE SAO PAULO',
    CreatedAt: '2018-01-29 15:12:24.290',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '153',
    label: 'CAIXA ECONOMICA DO ESTADO DO R.G.SUL',
    CreatedAt: '2018-01-29 15:12:24.290',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '165',
    label: 'BANCO NORCHEM S.A',
    CreatedAt: '2018-01-29 15:12:24.290',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '166',
    label: 'BANCO INTER-ATLANTICO S.A',
    CreatedAt: '2018-01-29 15:12:24.293',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '168',
    label: 'BANCO C.C.F. BRASIL S.A',
    CreatedAt: '2018-01-29 15:12:24.293',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '175',
    label: 'CONTINENTAL BANCO S.A',
    CreatedAt: '2018-01-29 15:12:24.293',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '184',
    label: 'BBA - CREDITANSTALT S.A',
    CreatedAt: '2018-01-29 15:12:24.293',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '199',
    label: 'BANCO FINANCIAL PORTUGUES',
    CreatedAt: '2018-01-29 15:12:24.293',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '200',
    label: 'BANCO FRICRISA AXELRUD S.A',
    CreatedAt: '2018-01-29 15:12:24.297',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '201',
    label: 'BANCO AUGUSTA INDUSTRIA E COMERCIAL S.A',
    CreatedAt: '2018-01-29 15:12:24.297',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '204',
    label: 'BANCO S.R.L S.A',
    CreatedAt: '2018-01-29 15:12:24.297',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '205',
    label: 'BANCO SUL AMERICA S.A',
    CreatedAt: '2018-01-29 15:12:24.297',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '206',
    label: 'BANCO MARTINELLI S.A',
    CreatedAt: '2018-01-29 15:12:24.297',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '208',
    label: 'BANCO PACTUAL S.A',
    CreatedAt: '2018-01-29 15:12:24.300',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '210',
    label: 'DEUTSCH SUDAMERIKANICHE BANK AG',
    CreatedAt: '2018-01-29 15:12:24.300',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '211',
    label: 'BANCO SISTEMA S.A',
    CreatedAt: '2018-01-29 15:12:24.300',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '213',
    label: 'BANCO ARBI S.A',
    CreatedAt: '2018-01-29 15:12:24.300',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '214',
    label: 'BANCO DIBENS S.A',
    CreatedAt: '2018-01-29 15:12:24.303',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '215',
    label: 'BANCO AMERICA DO SUL S.A',
    CreatedAt: '2018-01-29 15:12:24.303',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '216',
    label: 'BANCO REGIONAL MALCON S.A',
    CreatedAt: '2018-01-29 15:12:24.303',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '217',
    label: 'BANCO AGROINVEST S.A',
    CreatedAt: '2018-01-29 15:12:24.303',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '218',
    label: 'BS2',
    Document: '71.027.866/0001-34',
    CreatedAt: '2018-01-29 15:12:24.303',
    UpdatedAt: '2019-05-21 08:14:51.000',
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '219',
    label: 'BANCO DE CREDITO DE SAO PAULO S.A',
    CreatedAt: '2018-01-29 15:12:24.307',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '220',
    label: 'BANCO CREFISUL',
    CreatedAt: '2018-01-29 15:12:24.307',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '221',
    label: 'BANCO GRAPHUS S.A',
    CreatedAt: '2018-01-29 15:12:24.307',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '222',
    label: 'BANCO AGF BRASIL S. A.',
    CreatedAt: '2018-01-29 15:12:24.307',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '223',
    label: 'BANCO INTERUNION S.A',
    CreatedAt: '2018-01-29 15:12:24.307',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '224',
    label: 'BANCO FIBRA S.A',
    CreatedAt: '2018-01-29 15:12:24.307',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '225',
    label: 'BANCO BRASCAN S.A',
    CreatedAt: '2018-01-29 15:12:24.310',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '228',
    label: 'BANCO ICATU S.A',
    CreatedAt: '2018-01-29 15:12:24.310',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '229',
    label: 'BANCO CRUZEIRO S.A',
    CreatedAt: '2018-01-29 15:12:24.310',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '230',
    label: 'BANCO BANDEIRANTES S.A',
    CreatedAt: '2018-01-29 15:12:24.310',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '231',
    label: 'BANCO BOAVISTA S.A',
    CreatedAt: '2018-01-29 15:12:24.310',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '232',
    label: 'BANCO INTERPART S.A',
    CreatedAt: '2018-01-29 15:12:24.310',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '233',
    label: 'BANCO MAPPIN S.A',
    CreatedAt: '2018-01-29 15:12:24.313',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '234',
    label: 'BANCO LAVRA S.A.',
    CreatedAt: '2018-01-29 15:12:24.313',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '235',
    label: 'BANCO LIBERAL S.A',
    CreatedAt: '2018-01-29 15:12:24.313',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '236',
    label: 'BANCO CAMBIAL S.A',
    CreatedAt: '2018-01-29 15:12:24.313',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '239',
    label: 'BANCO BANCRED S.A',
    CreatedAt: '2018-01-29 15:12:24.317',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '240',
    label: 'BANCO DE CREDITO REAL DE MINAS GERAIS S.',
    CreatedAt: '2018-01-29 15:12:24.317',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '241',
    label: 'BANCO CLASSICO S.A',
    CreatedAt: '2018-01-29 15:12:24.317',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '242',
    label: 'BANCO EUROINVEST S.A',
    CreatedAt: '2018-01-29 15:12:24.317',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '243',
    label: 'BANCO MÁXIMA S.A',
    Document: '33.923.798/0001-00',
    CreatedAt: '2018-01-29 15:12:24.317',
    UpdatedAt: '2020-03-20 17:00:00.000',
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '244',
    label: 'BANCO CIDADE S.A',
    CreatedAt: '2018-01-29 15:12:24.320',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '245',
    label: 'BANCO EMPRESARIAL S.A',
    CreatedAt: '2018-01-29 15:12:24.320',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '246',
    label: 'BANCO ABC ROMA S.A',
    CreatedAt: '2018-01-29 15:12:24.320',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '247',
    label: 'BANCO OMEGA S.A',
    CreatedAt: '2018-01-29 15:12:24.320',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '249',
    label: 'BANCO INVESTCRED S.A',
    CreatedAt: '2018-01-29 15:12:24.320',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '250',
    label: 'BANCO SCHAHIN CURY S.A',
    CreatedAt: '2018-01-29 15:12:24.320',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '251',
    label: 'BANCO SAO JORGE S.A.',
    CreatedAt: '2018-01-29 15:12:24.323',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '252',
    label: 'BANCO FININVEST S.A',
    CreatedAt: '2018-01-29 15:12:24.323',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '254',
    label: 'BANCO PARANA BANCO S.A',
    CreatedAt: '2018-01-29 15:12:24.323',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '255',
    label: 'MILBANCO S.A.',
    CreatedAt: '2018-01-29 15:12:24.323',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '256',
    label: 'BANCO GULVINVEST S.A',
    CreatedAt: '2018-01-29 15:12:24.323',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '258',
    label: 'BANCO INDUSCRED S.A',
    CreatedAt: '2018-01-29 15:12:24.327',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '261',
    label: 'BANCO VARIG S.A',
    CreatedAt: '2018-01-29 15:12:24.327',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '262',
    label: 'BANCO BOREAL S.A',
    CreatedAt: '2018-01-29 15:12:24.327',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '263',
    label: 'BANCO CACIQUE',
    CreatedAt: '2018-01-29 15:12:24.327',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '264',
    label: 'BANCO PERFORMANCE S.A',
    CreatedAt: '2018-01-29 15:12:24.330',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '265',
    label: 'BANCO FATOR S.A',
    CreatedAt: '2018-01-29 15:12:24.330',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '266',
    label: 'BANCO CEDULA S.A',
    CreatedAt: '2018-01-29 15:12:24.330',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '267',
    label: 'BANCO BBM-COM.C.IMOB.CFI S.A.',
    CreatedAt: '2018-01-29 15:12:24.330',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '275',
    label: 'BANCO REAL S.A',
    CreatedAt: '2018-01-29 15:12:24.330',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '277',
    label: 'BANCO PLANIBANC S.A',
    CreatedAt: '2018-01-29 15:12:24.330',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '282',
    label: 'BANCO BRASILEIRO COMERCIAL',
    CreatedAt: '2018-01-29 15:12:24.330',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '291',
    label: 'BANCO DE CREDITO NACIONAL S.A',
    CreatedAt: '2018-01-29 15:12:24.333',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '294',
    label: 'BCR - BANCO DE CREDITO REAL S.A',
    CreatedAt: '2018-01-29 15:12:24.333',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '295',
    label: 'BANCO CREDIPLAN S.A',
    CreatedAt: '2018-01-29 15:12:24.333',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '300',
    label: 'BANCO DE LA NACION ARGENTINA S.A',
    CreatedAt: '2018-01-29 15:12:24.333',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '302',
    label: 'BANCO DO PROGRESSO S.A',
    CreatedAt: '2018-01-29 15:12:24.337',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '303',
    label: 'BANCO HNF S.A.',
    CreatedAt: '2018-01-29 15:12:24.337',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '304',
    label: 'BANCO PONTUAL S.A',
    CreatedAt: '2018-01-29 15:12:24.337',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '308',
    label: 'BANCO COMERCIAL BANCESA S.A.',
    CreatedAt: '2018-01-29 15:12:24.337',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '318',
    label: 'BANCO B.M.G. S.A',
    CreatedAt: '2018-01-29 15:12:24.337',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '320',
    label: 'BANCO INDUSTRIAL E COMERCIAL',
    CreatedAt: '2018-01-29 15:12:24.340',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '346',
    label: 'BANCO FRANCES E BRASILEIRO S.A',
    CreatedAt: '2018-01-29 15:12:24.340',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '347',
    label: 'BANCO SUDAMERIS BRASIL S.A',
    CreatedAt: '2018-01-29 15:12:24.340',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '351',
    label: 'BANCO BOZANO SIMONSEN S.A',
    CreatedAt: '2018-01-29 15:12:24.340',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '353',
    label: 'BANCO GERAL DO COMERCIO S.A',
    CreatedAt: '2018-01-29 15:12:24.340',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '356',
    label: 'ABN AMRO S.A',
    CreatedAt: '2018-01-29 15:12:24.340',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '366',
    label: 'BANCO SOGERAL S.A',
    CreatedAt: '2018-01-29 15:12:24.343',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '369',
    label: 'PONTUAL',
    CreatedAt: '2018-01-29 15:12:24.343',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '370',
    label: 'BEAL - BANCO EUROPEU PARA AMERICA LATINA',
    CreatedAt: '2018-01-29 15:12:24.343',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '372',
    label: 'BANCO ITAMARATI S.A',
    CreatedAt: '2018-01-29 15:12:24.347',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '375',
    label: 'BANCO FENICIA S.A',
    CreatedAt: '2018-01-29 15:12:24.347',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '376',
    label: 'CHASE MANHATTAN BANK S.A',
    CreatedAt: '2018-01-29 15:12:24.347',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '388',
    label: 'BANCO MERCANTIL DE DESCONTOS S/A',
    CreatedAt: '2018-01-29 15:12:24.347',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '389',
    label: 'BANCO MERCANTIL DO BRASIL S.A',
    CreatedAt: '2018-01-29 15:12:24.347',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '392',
    label: 'BANCO MERCANTIL DE SAO PAULO S.A',
    CreatedAt: '2018-01-29 15:12:24.350',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '394',
    label: 'BANCO B.M.C. S.A',
    CreatedAt: '2018-01-29 15:12:24.350',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '409',
    label: 'UNIBANCO - UNIAO DOS BANCOS BRASILEIROS',
    CreatedAt: '2018-01-29 15:12:24.350',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '412',
    label: 'BANCO NACIONAL DA BAHIA S.A',
    CreatedAt: '2018-01-29 15:12:24.350',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '415',
    label: 'BANCO NACIONAL S.A',
    CreatedAt: '2018-01-29 15:12:24.350',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '420',
    label: 'BANCO NACIONAL DO NORTE S.A',
    CreatedAt: '2018-01-29 15:12:24.350',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '424',
    label: 'BANCO NOROESTE S.A',
    CreatedAt: '2018-01-29 15:12:24.353',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '434',
    label: 'BANCO FORTALEZA S.A',
    CreatedAt: '2018-01-29 15:12:24.353',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '453',
    label: 'BANCO RURAL S.A',
    CreatedAt: '2018-01-29 15:12:24.353',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '456',
    label: 'BANCO TOKIO S.A',
    CreatedAt: '2018-01-29 15:12:24.353',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '464',
    label: 'BANCO SUMITOMO BRASILEIRO S.A',
    CreatedAt: '2018-01-29 15:12:24.357',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '466',
    label: 'BANCO MITSUBISHI BRASILEIRO S.A',
    CreatedAt: '2018-01-29 15:12:24.357',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '472',
    label: 'LLOYDS BANK PLC',
    CreatedAt: '2018-01-29 15:12:24.357',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '473',
    label: 'BANCO FINANCIAL PORTUGUES S.A',
    CreatedAt: '2018-01-29 15:12:24.357',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '477',
    label: 'CITIBANK N.A',
    CreatedAt: '2018-01-29 15:12:24.357',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '479',
    label: 'BANCO DE BOSTON S.A',
    CreatedAt: '2018-01-29 15:12:24.360',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '480',
    label: 'BANCO PORTUGUES DO ATLANTICO-BRASIL S.A',
    CreatedAt: '2018-01-29 15:12:24.360',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '483',
    label: 'BANCO AGRIMISA S.A.',
    CreatedAt: '2018-01-29 15:12:24.360',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '487',
    label: 'DEUTSCHE BANK S.A - BANCO ALEMAO',
    CreatedAt: '2018-01-29 15:12:24.360',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '488',
    label: 'BANCO J. P. MORGAN S.A',
    CreatedAt: '2018-01-29 15:12:24.360',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '489',
    label: 'BANESTO BANCO URUGAUAY S.A',
    CreatedAt: '2018-01-29 15:12:24.360',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '492',
    label: 'INTERNATIONALE NEDERLANDEN BANK N.V.',
    CreatedAt: '2018-01-29 15:12:24.363',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '493',
    label: 'BANCO UNION S.A.C.A',
    CreatedAt: '2018-01-29 15:12:24.363',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '494',
    label: 'BANCO LA REP. ORIENTAL DEL URUGUAY',
    CreatedAt: '2018-01-29 15:12:24.363',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '495',
    label: 'BANCO LA PROVINCIA DE BUENOS AIRES',
    CreatedAt: '2018-01-29 15:12:24.363',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '496',
    label: 'BANCO EXTERIOR DE ESPANA S.A',
    CreatedAt: '2018-01-29 15:12:24.363',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '498',
    label: 'CENTRO HISPANO BANCO',
    CreatedAt: '2018-01-29 15:12:24.367',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '499',
    label: 'BANCO IOCHPE S.A',
    CreatedAt: '2018-01-29 15:12:24.367',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '501',
    label: 'BANCO BRASILEIRO IRAQUIANO S.A.',
    CreatedAt: '2018-01-29 15:12:24.367',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '502',
    label: 'BANCO SANTANDER DE NEGOCIOS S.A',
    CreatedAt: '2018-01-29 15:12:24.367',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '504',
    label: 'BANCO MULTIPLIC S.A',
    CreatedAt: '2018-01-29 15:12:24.367',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '505',
    label: 'BANCO GARANTIA S.A',
    CreatedAt: '2018-01-29 15:12:24.370',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '600',
    label: 'BANCO LUSO BRASILEIRO S.A',
    CreatedAt: '2018-01-29 15:12:24.370',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '601',
    label: 'BFC BANCO S.A.',
    CreatedAt: '2018-01-29 15:12:24.370',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '602',
    label: 'BANCO PATENTE S.A',
    CreatedAt: '2018-01-29 15:12:24.370',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '604',
    label: 'BANCO INDUSTRIAL DO BRASIL S.A',
    CreatedAt: '2018-01-29 15:12:24.370',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '607',
    label: 'BANCO SANTOS NEVES S.A',
    CreatedAt: '2018-01-29 15:12:24.373',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '608',
    label: 'BANCO OPEN S.A',
    CreatedAt: '2018-01-29 15:12:24.373',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '610',
    label: 'BANCO V.R. S.A',
    CreatedAt: '2018-01-29 15:12:24.373',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '611',
    label: 'BANCO PAULISTA S.A',
    CreatedAt: '2018-01-29 15:12:24.373',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '612',
    label: 'BANCO GUANABARA S.A',
    CreatedAt: '2018-01-29 15:12:24.377',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '613',
    label: 'BANCO PECUNIA S.A',
    CreatedAt: '2018-01-29 15:12:24.377',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '616',
    label: 'BANCO INTERPACIFICO S.A',
    CreatedAt: '2018-01-29 15:12:24.377',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '617',
    label: 'BANCO INVESTOR S.A.',
    CreatedAt: '2018-01-29 15:12:24.377',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '618',
    label: 'BANCO TENDENCIA S.A',
    CreatedAt: '2018-01-29 15:12:24.377',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '621',
    label: 'BANCO APLICAP S.A.',
    CreatedAt: '2018-01-29 15:12:24.380',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '622',
    label: 'BANCO DRACMA S.A',
    CreatedAt: '2018-01-29 15:12:24.380',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '623',
    label: 'BANCO PAlabelRICANO S.A',
    CreatedAt: '2018-01-29 15:12:24.380',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '624',
    label: 'BANCO GENERAL MOTORS S.A',
    CreatedAt: '2018-01-29 15:12:24.380',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '625',
    label: 'BANCO ARAUCARIA S.A',
    CreatedAt: '2018-01-29 15:12:24.380',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '626',
    label: 'BANCO FICSA S.A',
    CreatedAt: '2018-01-29 15:12:24.380',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '627',
    label: 'BANCO DESTAK S.A',
    CreatedAt: '2018-01-29 15:12:24.383',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '628',
    label: 'BANCO CRITERIUM S.A',
    CreatedAt: '2018-01-29 15:12:24.383',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '629',
    label: 'BANCORP BANCO COML. E. DE INVESTMENTO',
    CreatedAt: '2018-01-29 15:12:24.383',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '630',
    label: 'BANCO INTERCAP S.A',
    CreatedAt: '2018-01-29 15:12:24.383',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '633',
    label: 'BANCO REDIMENTO S.A',
    CreatedAt: '2018-01-29 15:12:24.383',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '634',
    label: 'BANCO TRIANGULO S.A',
    CreatedAt: '2018-01-29 15:12:24.387',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '635',
    label: 'BANCO DO ESTADO DO AMAPA S.A',
    CreatedAt: '2018-01-29 15:12:24.387',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '637',
    label: 'BANCO SOFISA S.A',
    CreatedAt: '2018-01-29 15:12:24.387',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '638',
    label: 'BANCO PROSPER S.A',
    CreatedAt: '2018-01-29 15:12:24.387',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '639',
    label: 'BIG S.A. - BANCO IRMAOS GUIMARAES',
    CreatedAt: '2018-01-29 15:12:24.387',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '640',
    label: 'BANCO DE CREDITO METROPOLITANO S.A',
    CreatedAt: '2018-01-29 15:12:24.390',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '641',
    label: 'BANCO EXCEL ECONOMICO S/A',
    CreatedAt: '2018-01-29 15:12:24.390',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '643',
    label: 'BANCO SEGMENTO S.A',
    CreatedAt: '2018-01-29 15:12:24.390',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '645',
    label: 'BANCO DO ESTADO DE RORAIMA S.A',
    CreatedAt: '2018-01-29 15:12:24.390',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '647',
    label: 'BANCO MARKA S.A',
    CreatedAt: '2018-01-29 15:12:24.390',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '648',
    label: 'BANCO ATLANTIS S.A',
    CreatedAt: '2018-01-29 15:12:24.390',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '649',
    label: 'BANCO DIMENSAO S.A',
    CreatedAt: '2018-01-29 15:12:24.390',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '650',
    label: 'BANCO PEBB S.A',
    CreatedAt: '2018-01-29 15:12:24.393',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '652',
    label: 'BANCO FRANCES E BRASILEIRO SA',
    CreatedAt: '2018-01-29 15:12:24.393',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '653',
    label: 'BANCO INDUSVAL S.A',
    CreatedAt: '2018-01-29 15:12:24.393',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '654',
    label: 'BANCO A. J. RENNER S.A',
    CreatedAt: '2018-01-29 15:12:24.393',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '655',
    label: 'BANCO VOTORANTIM S.A.',
    CreatedAt: '2018-01-29 15:12:24.393',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '656',
    label: 'BANCO MATRIX S.A',
    CreatedAt: '2018-01-29 15:12:24.397',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '657',
    label: 'BANCO TECNICORP S.A',
    CreatedAt: '2018-01-29 15:12:24.397',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '658',
    label: 'BANCO PORTO REAL S.A',
    CreatedAt: '2018-01-29 15:12:24.397',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '702',
    label: 'BANCO SANTOS S.A',
    CreatedAt: '2018-01-29 15:12:24.397',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '705',
    label: 'BANCO INVESTCORP S.A.',
    CreatedAt: '2018-01-29 15:12:24.400',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '707',
    label: 'BANCO DAYCOVAL S.A',
    CreatedAt: '2018-01-29 15:12:24.400',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '711',
    label: 'BANCO VETOR S.A.',
    CreatedAt: '2018-01-29 15:12:24.400',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '713',
    label: 'BANCO CINDAM S.A',
    CreatedAt: '2018-01-29 15:12:24.400',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '715',
    label: 'BANCO VEGA S.A',
    CreatedAt: '2018-01-29 15:12:24.400',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '718',
    label: 'BANCO OPERADOR S.A',
    CreatedAt: '2018-01-29 15:12:24.400',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '719',
    label: 'BANCO PRIMUS S.A',
    CreatedAt: '2018-01-29 15:12:24.400',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '720',
    label: 'BANCO MAXINVEST S.A',
    CreatedAt: '2018-01-29 15:12:24.403',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '721',
    label: 'BANCO CREDIBEL S.A',
    CreatedAt: '2018-01-29 15:12:24.403',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '722',
    label: 'BANCO INTERIOR DE SAO PAULO S.A',
    CreatedAt: '2018-01-29 15:12:24.403',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '724',
    label: 'BANCO PORTO SEGURO S.A',
    CreatedAt: '2018-01-29 15:12:24.403',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '725',
    label: 'BANCO FINABANCO S.A',
    CreatedAt: '2018-01-29 15:12:24.407',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '726',
    label: 'BANCO UNIVERSAL S.A',
    CreatedAt: '2018-01-29 15:12:24.407',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '728',
    label: 'BANCO FITAL S.A',
    CreatedAt: '2018-01-29 15:12:24.407',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '729',
    label: 'BANCO FONTE S.A',
    CreatedAt: '2018-01-29 15:12:24.407',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '730',
    label: 'BANCO COMERCIAL PARAGUAYO S.A',
    CreatedAt: '2018-01-29 15:12:24.407',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '731',
    label: 'BANCO GNPP S.A.',
    CreatedAt: '2018-01-29 15:12:24.410',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '732',
    label: 'BANCO PREMIER S.A.',
    CreatedAt: '2018-01-29 15:12:24.410',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '733',
    label: 'BANCO NACOES S.A.',
    CreatedAt: '2018-01-29 15:12:24.410',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '734',
    label: 'BANCO GERDAU S.A',
    CreatedAt: '2018-01-29 15:12:24.410',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '735',
    label: 'BANCO NEON',
    CreatedAt: '2018-01-29 15:12:24.410',
    UpdatedAt: '2019-11-12 19:15:00.000',
    DeletedAt: '2019-11-12 19:15:00.000',
    IsDeleted: true
  },
  {
    value: '736',
    label: 'BANCO UNITED S.A',
    CreatedAt: '2018-01-29 15:12:24.410',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '737',
    label: 'THECA',
    CreatedAt: '2018-01-29 15:12:24.413',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '738',
    label: 'MARADA',
    CreatedAt: '2018-01-29 15:12:24.413',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '739',
    label: 'BGN',
    CreatedAt: '2018-01-29 15:12:24.413',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '740',
    label: 'BCN BARCLAYS',
    CreatedAt: '2018-01-29 15:12:24.413',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '741',
    label: 'BRP',
    CreatedAt: '2018-01-29 15:12:24.413',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '742',
    label: 'EQUATORIAL',
    CreatedAt: '2018-01-29 15:12:24.417',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '743',
    label: 'BANCO EMBLEMA S.A',
    CreatedAt: '2018-01-29 15:12:24.417',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '744',
    label: 'THE FIRST NATIONAL BANK OF BOSTON',
    CreatedAt: '2018-01-29 15:12:24.417',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '746',
    label: 'MODAL S.A.',
    CreatedAt: '2018-01-29 15:12:24.417',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '747',
    label: 'RABOBANK DO BRASIL',
    Document: '01.023.570/0001-60',
    CreatedAt: '2018-01-29 15:12:24.420',
    UpdatedAt: '2020-04-23 22:00:00.000',
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '748',
    label: 'SICREDI',
    CreatedAt: '2018-01-29 15:12:24.420',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '749',
    label: 'BRMSANTIL SA',
    CreatedAt: '2018-01-29 15:12:24.420',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '750',
    label: 'BANCO REPUBLIC NATIONAL OF NEW YORK (BRA',
    CreatedAt: '2018-01-29 15:12:24.420',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '751',
    label: 'DRESDNER BANK LATEIlabelRIKA-BRASIL S/A',
    CreatedAt: '2018-01-29 15:12:24.420',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '752',
    label: 'BANCO BANQUE NATIONALE DE PARIS BRASIL S',
    CreatedAt: '2018-01-29 15:12:24.420',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '753',
    label: 'BANCO COMERCIAL URUGUAI S.A.',
    CreatedAt: '2018-01-29 15:12:24.423',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '755',
    label: 'BANCO MERRILL LYNCH S.A',
    CreatedAt: '2018-01-29 15:12:24.423',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '757',
    label: 'BANCO KEB DO BRASIL S.A.',
    CreatedAt: '2018-01-29 15:12:24.423',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '260',
    label: 'Nu Pagamentos S.A',
    Document: '18.236.120/0001-58',
    CreatedAt: '2018-01-29 16:47:35.153',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '102',
    label: 'XP INVESTIMENTOS',
    CreatedAt: '2018-01-29 16:47:35.210',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '336',
    label: 'BANCO C6 S.A.',
    Document: '31.872.495/0001-72',
    CreatedAt: '2019-08-02 13:11:00.000',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '290',
    label: 'PagSeguro Internet S.A.',
    Document: '08.561.701/0001-01',
    CreatedAt: '2019-08-21 12:48:00.000',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '323',
    label: 'MercadoPago.com Representações Ltda.',
    Document: '10.573.521/0001-91',
    CreatedAt: '2019-09-13 15:23:00.000',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '332',
    label: 'Acesso Soluções de Pagamento S.A.',
    Document: '13.140.088/0001-99',
    CreatedAt: '2019-12-26 15:00:00.000',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '325',
    label: 'Órama DTVM S.A.',
    Document: '13.293.225/0001-25',
    CreatedAt: '2020-01-15 19:27:00.000',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '85',
    label: 'COOPERATIVA CENTRAL DE CREDITO - AILOS',
    Document: '05.463.212/0001-29',
    CreatedAt: '2020-03-20 18:04:00.000',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '125',
    label: 'PLURAL S.A. BANCO MULTIPLO',
    Document: '45.246.410/0001-55',
    CreatedAt: '2020-03-20 18:13:00.000',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '197',
    label: 'STONE PAGAMENTOS S.A.',
    Document: '16.501.555/0002-38',
    CreatedAt: '2020-04-24 12:58:00.000',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '380',
    label: 'PICPAY SERVIÇOS S.A.',
    Document: '22.896.431/0001-10',
    CreatedAt: '2021-01-06 08:36:00.000',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  },
  {
    value: '335',
    label: 'BANCO DIGIO S.A.',
    Document: '27.098.060/0001-45',
    CreatedAt: '2021-01-20 08:26:00.000',
    UpdatedAt: null,
    DeletedAt: null,
    IsDeleted: false
  }
]

export default jsonListOfBanks
