import { toast } from 'react-toastify'

const configNotify = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}

export const notify = ({ message, type }) => {
  if (type === 'success') {
    toast.success(message, configNotify)
  }
  if (type === 'info') {
    toast.info(message, configNotify)
  }
  if (type === 'warn') {
    toast.warn(message, configNotify)
  }
  if (type === 'error') {
    toast.error(message, configNotify)
  }
  if (type === 'default') {
    toast(message, configNotify)
  }
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function fisrtName(full_name) {
  return full_name.split(' ')[0]
}
