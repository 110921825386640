import axios from 'axios'

// const tmpEndpoint = 'https://sixbase.sa.ngrok.io/api/membership'
const { host } = window.location
let tmpEndpoint = null
if (host.includes('sandbox')) {
  tmpEndpoint = 'https://api-b4-sandbox.b4you.com.br/api/membership'
} else if (host.includes('membros.b4you.com.br')) {
  tmpEndpoint = 'https://api-b4.b4you.com.br/api/membership'
} else {
  tmpEndpoint = 'https://api-b4-sandbox.b4you.com.br/api/membership'
}
export const endpoint = tmpEndpoint

const api = axios.create({
  baseURL: endpoint,
  withCredentials: true
})

export default api
