import {useState} from 'react'
import { BrowserRouter as Router, useHistory } from 'react-router-dom'

const Pager = ({ size, limit, active, url }) => {
  const history = useHistory()
  const totalPages = Math.ceil(size / limit)
  const arrayPages = []
  const [currentPage, setCurrentPage] = useState(1)

  for (let i = 1; i <= totalPages; i++) {
    arrayPages.push(i)
  }

  const handleNavigation = (nextPage) => {
    history.push(`${url}/${nextPage}`)
  }

  return (
    <div>
      {totalPages > 1 && (
        <div className="pager">
          <ul className="uk-pagination uk-margin justify-content-center">
            {arrayPages.map((item, index) => (
              <li
                key={index}
                className={item === currentPage ? 'uk-active' : undefined}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCurrentPage(item)
                  handleNavigation(item)
                }}
              >
                <span>{item}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default Pager
