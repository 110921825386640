import './style.scss'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import api, { endpoint } from '../../providers/api'
import EbookCover from '../../components/EbookCover'
import Author from '../../components/Author'
import Loader from '../../utils/loader'
import { Col } from 'react-bootstrap'

function PageEbookSingle() {
  const { uuid } = useParams()
  const [ebook, setEbook] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    api
      .get(`products/${uuid}`)
      .then((response) => {
        setEbook(response.data)
        setLoading(false)
      })
      .catch((err) => {})
  }, [])

  const downloadEbook = (uuidEbook) => {
    window.open(`${endpoint}/products/pdf/download/${uuid}/${uuidEbook}`)
  }

  return (
    <>
      {ebook ? (
        <section id="page-ebooks">
          <main className="page-content">
            <div className="container container-content">
              <div className="row">
                <div className="col-12">
                  <h2 className="mb-4" style={{ color: '#fff' }}>
                    {ebook.name}
                  </h2>
                </div>

                <div className="col-md-3 col-lg-4">
                  <div className="mb-5">
                    <div className="c-ebook">
                      <EbookCover src={ebook.ebook_cover} />
                    </div>

                    {/* <ul className='uk-list uk-list-divider text-small mt-lg-4'>
                        <li>Adquirido em 12/12/2018</li>
                      </ul> */}
                  </div>
                </div>

                <div className="col-lg-7 col-md-9 info-ebook">
                  {ebook.description && (
                    <>
                      <h2>Descrição</h2>
                      <div className="uk-card-default uk-card-body">
                        <div className="description">{ebook.description}</div>
                      </div>
                    </>
                  )}
                  <h2 className={ebook.description && 'mt-4'}>Arquivos</h2>
                  <div className="uk-card-default uk-card-body">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <tbody>
                          {ebook.files.map((item, index) => (
                            <tr key={index}>
                              <td style={{ verticalAlign: 'middle' }}>
                                <div
                                  className="d-inline-block pointer"
                                  onClick={() => downloadEbook(item.uuid)}
                                >
                                  <i className="icon-feather-file mr-2" />
                                  <span>{item.name}</span>
                                </div>
                              </td>
                            </tr>
                          ))}
                          {ebook.files.length === 0 && (
                            <>
                              <div>Nenhum arquivo encontrado...</div>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {ebook.instructions && ebook.instructions.length > 0 && (
                    <>
                      <h4> Instruções </h4>
                      <div className="uk-card-default uk-card-body">
                        <p>{ebook.instructions}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <Col md={6} className="p-0">
                <h2 className="mb-4 mt-4">Autor</h2>

                <div className="uk-card-default uk-card-body mt-4">
                  <Author producer={ebook.producer} />
                </div>
              </Col>
            </div>
          </main>
        </section>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  )
}

export default PageEbookSingle
