import { useState, useEffect } from 'react'
import { Row, Col, Modal, Button, Form, Alert } from 'react-bootstrap'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams
} from 'react-router-dom'
import './style.scss'
import api from '../../providers/api'
import { useStudent } from '../../providers/contextStudent'
import logo from '../../assets/images/sixbase/logo-horizontal.png'

function PagePassword() {
  const [show, setShow] = useState(false)
  const [fieldPassword, setFieldPassword] = useState('')
  const [fieldPassword2, setFieldPassword2] = useState('')
  const [passwordForce, setPasswordForce] = useState(null)
  const [forgotEmail, setForgotEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const { setStudent } = useStudent()
  const [warning, setWarning] = useState(null)
  const [forgotReturn, setForgotReturn] = useState(null)
  const [tokenValid, setTokenValid] = useState(null)
  const [userName, setUserName] = useState(null)
  const { token, type } = useParams()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const CheckPasswordStrength = (password) => {
    const password_strength = document.getElementById('password_strength')

    // if textBox is empty
    if (password.length === 0) {
      password_strength.innerHTML = ''
      return
    }

    // Regular Expressions
    const regex = new Array()
    regex.push('[A-Z]') // For Uppercase Alphabet
    regex.push('[a-z]') // For Lowercase Alphabet
    regex.push('[0-9]') // For Numeric Digits
    regex.push('[$@$!%*#?&]') // For Special Characters

    let passed = 0

    // Validation for each Regular Expression
    for (let i = 0; i < regex.length; i++) {
      if (new RegExp(regex[i]).test(password)) {
        passed++
      }
    }

    // Validation for Length of Password
    if (passed > 2 && password.length > 8) {
      passed++
    }

    // Display of Status
    let color = ''
    let passwordStrength = ''
    switch (passed) {
      case 0:
        break
      case 1:
        passwordStrength = 'Senha fraca.'
        color = 'Red'
        break
      case 2:
        passwordStrength = 'Senha boa.'
        color = 'darkorange'
        break
      case 3:
        passwordStrength = 'Senha forte.'
        color = 'Green'
        break
      case 4:
        passwordStrength = 'Senha muito forte.'
        color = 'darkgreen'
        break
    }

    setPasswordForce({
      text: passwordStrength,
      color
    })
  }

  const typeRegister = type === undefined ? 'first' : type

  useEffect(() => {
    api
      .get(`/auth/validate/token/${token}`)
      .then((response) => {
        if (response.data === false) {
          // token invalido
          setTokenValid(false)
        } else {
          setTokenValid(true)
          setUserName(response.data.full_name)
        }
      })
      .catch((err) => {
        setTokenValid(false)
      })
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    setIsLoading(true)
    setWarning(null)

    const fields = {
      password: fieldPassword,
      token
    }

    api
      .post('/auth/change/password', fields)
      .then((response) => {
        setIsLoading(false)

        window.location.href = window.location.origin
        // history.push('/cursos');
      })
      .catch((err) => {
        console.log(err.response)
        setWarning(err.response.data.message)
        setIsLoading(false)
      })
  }

  const formValid = () => {
    if (fieldPassword.length < 6) {
      return false
    }
    if (fieldPassword !== fieldPassword2) {
      return false
    }

    return true
  }

  return (
    <>
      <div className="access-bg">
        <div className="access-card">
          <img src={logo} alt="Logotipo B4you" />
          <h2 className="">
            {typeRegister === 'first' ? 'Primeiro Acesso' : 'Nova Senha'}
          </h2>
          {tokenValid === null ? (
            <div className="validating">
              <i className="bx bx-loader-alt bx-spin"></i>
              <span>Aguarde...</span>
            </div>
          ) : (
            <>
              {!tokenValid ? (
                <div className="invalid">
                  <h3
                    className="text-danger"
                    style={{ fontSize: 21, fontWeight: 500 }}
                  >
                    Este link de acesso expirou
                  </h3>
                  <p>Acesse com seu e-mail e senha clicando no botão abaixo.</p>
                  <p className="d-block mt-3">
                    Caso não lembre sua senha, clique em Esqueci minha senha na
                    tela de login.
                  </p>

                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      history.push('/')
                    }}
                  >
                    Fazer login novamente
                  </button>
                </div>
              ) : (
                <>
                  <div className="mb-4 ">
                    <span>
                      {userName}, que bom te ver por aqui! Cadastre sua senha de
                      acesso para iniciar.
                    </span>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="uk-form-group">
                      {warning && <Alert variant="danger">{warning}</Alert>}
                    </div>

                    <Row className="input-list">
                      <Col className="col-12">
                        <Form.Group>
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Senha"
                            onChange={(e) => {
                              setFieldPassword(e.target.value)
                              CheckPasswordStrength(e.target.value)
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col className="col-12">
                        <Form.Group className="mb-0">
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Confirmar Senha"
                            className="mb-0"
                            onChange={(e) => {
                              setFieldPassword2(e.target.value)
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    {passwordForce && (
                      <div>
                        <span
                          id="password_strength"
                          style={{
                            display: 'block',
                            color: passwordForce.color,
                            fontWeight: 400,
                            fontSize: 14,
                            marginTop: 16,
                            marginLeft: 4
                          }}
                        >
                          {passwordForce.text}
                        </span>
                      </div>
                    )}

                    <Row>
                      <Col className="col-12">
                        <Button
                          type="submit"
                          className="btn btn-default"
                          disabled={!formValid() || isLoading}
                        >
                          <span>
                            {!isLoading ? (
                              typeRegister === 'n' ? (
                                'Confirmar Senha e Acessar Plataforma'
                              ) : (
                                'Registrar Nova Senha'
                              )
                            ) : (
                              <i className="bx bx-loader-alt bx-spin"></i>
                            )}
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default PagePassword
