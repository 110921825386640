import React from 'react'
import placeholder from '../assets/images/avatar-placeholder.png'

const AvatarImage = ({ src, className = null, size = null }) => {
  const image = src || placeholder

  return (
    <div
      className={className ? `avatar-image ${className}` : `avatar-image`}
      style={size ? { width: size, height: size } : undefined}
    >
      <img src={image} alt="" />
    </div>
  )
}

export default AvatarImage
