import { useState, useEffect } from 'react'
import api from '../providers/api'
import './custom.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

const ProductPageComponent = () => {
  const history = useHistory()
  const [banner, setBanner] = useState(null)

  useEffect(() => {
    const params = window.location.pathname.split('/')
    const uuid = params[params.length - 1]
    api
      .get(`products/home?uuid=${uuid}`)
      .then((r) => {
        setBanner(r.data.home)
      })
      .catch((err) => err.data)
  }, [window.location])

  const setProgressCourse = (progress) => {
    if (progress === 0) {
      return 'Iniciar curso'
    }
    if (progress === 1) {
      return 'Curso finalizado!'
    }
    return `Retomar Curso (${Number(progress * 100).toFixed(0)}%)`
  }

  return (
    <div id="page-home-component">
      {banner ? (
        <div
          className="page-home-component-bg"
          style={{ height: !banner.banner && 370 }}
        >
          {window.innerWidth < 800 ? (
            <>
              {banner.banner_mobile ? (
                <img
                  className="background intro-background-banner-mobile"
                  src={banner.banner_mobile}
                />
              ) : (
                <div className="background-color"></div>
              )}
              <div className="eclipse"></div>
            </>
          ) : (
            <>
              {banner.banner ? (
                <img
                  className="background intro-background-banner"
                  src={banner.banner}
                />
              ) : (
                <div className="background-color"></div>
              )}
              <div className="eclipse"></div>
            </>
          )}

          {banner && (
            <div className="intro">
              <Container>
                <Row>
                  <Col>
                    <div className="content">
                      <span className="top">{banner.category.label}</span>
                      <h1>{banner.name}</h1>
                      <div className="bottom-bar">
                        <div className="right">
                          {banner.next_lesson_id && (
                            <>
                              {banner.type === 'video' && (
                                <div
                                  className="btn-primary"
                                  onClick={() => {
                                    history.push(
                                      `/aprenda/${banner.slug}/${banner.uuid}/${banner.next_lesson_id}`
                                    )
                                  }}
                                >
                                  <>
                                    <i className="bx bx-play"></i>
                                    <span>
                                      {setProgressCourse(banner.progress)}
                                    </span>
                                  </>
                                </div>
                              )}
                            </>
                          )}
                          {!history.location.pathname.includes(
                            'cursos',
                            'ebooks'
                          ) &&
                            (banner.type === 'video' ? (
                              <Link
                                to={`/cursos/${banner.slug}/${banner.uuid}`}
                              >
                                <div className="btn-secundary">
                                  <i className="bx bx-info-circle"></i>
                                  <span>Mais informações</span>
                                </div>
                              </Link>
                            ) : (
                              <Link
                                to={`/ebooks/${banner.slug}/${banner.uuid}`}
                              >
                                <div className="btn-primary">
                                  <i className="bx bx-play"></i>
                                  <span>Ver e-book</span>
                                </div>
                              </Link>
                            ))}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          )}
        </div>
      ) : (
        <div className="page-home-component-bg" style={{ height: 200 }}>
          <div className="background-color"></div>
          <div className="eclipse"></div>
        </div>
      )}
    </div>
  )
}

export default ProductPageComponent
