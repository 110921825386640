import { useEffect, useRef } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import AvatarImage from './AvatarImage'
import logo from '../assets/images/sixbase/logo-horizontal.png'
import { useStudent } from '../providers/contextStudent'
import { fisrtName } from './functions'
import './custom.scss'

const Header = () => {
  const { student } = useStudent()
  const btnMobile = useRef(null)
  const nav = useRef(null)
  const headerWidget = useRef(null)

  const toggleMenu = (e) => {
    nav.current.classList.toggle('activeMenuMobile')
  }

  const handleCloseNavWhenChangeThePage = () => {
    nav.current.classList.remove('activeMenuMobile')
  }

  return (
    <>
      {student && (
        <header>
          <Container>
            <div className="header-container">
              <div className="header-left">
                <Link to="/" className="logo">
                  <img src={logo} alt="" />
                </Link>
              </div>
              <nav ref={nav} className="nav2">
                <ul className="menu">
                  <li>
                    <NavLink
                      to="/"
                      activeClassName="nav-active"
                      onClick={handleCloseNavWhenChangeThePage}
                      exact
                    >
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/produtos"
                      activeClassName="nav-active"
                      onClick={handleCloseNavWhenChangeThePage}
                    >
                      Produtos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/assinaturas"
                      activeClassName="nav-active"
                      onClick={handleCloseNavWhenChangeThePage}
                    >
                      Assinaturas
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/atividades"
                      activeClassName="nav-active"
                      onClick={handleCloseNavWhenChangeThePage}
                    >
                      Atividades
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://ajuda.b4you.com.br"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Ajuda
                    </a>
                  </li>
                  <li>
                    <NavLink
                      to="/aluno"
                      className="dropdown-link"
                      onClick={handleCloseNavWhenChangeThePage}
                    >
                      Minha Conta
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/sair"
                      className="dropdown-link"
                      onClick={handleCloseNavWhenChangeThePage}
                    >
                      <i className="icon-feather-log-out" /> Sair
                    </NavLink>
                  </li>
                </ul>
              </nav>
              <nav className="nav1">
                <ul className="menu">
                  <li>
                    <NavLink to="/" activeClassName="nav-active" exact>
                      <i class="bx bx-home-smile"></i>
                      <span>Home</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/produtos" activeClassName="nav-active">
                      <i className="bx bx-purchase-tag-alt" />
                      <span>Produtos</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/assinaturas" activeClassName="nav-active">
                      <i className="bx bx-analyse" />
                      <span>Assinaturas</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/atividades" activeClassName="nav-active">
                      <i className="bx bx-copy-alt" />
                      <span>Atividades</span>
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="https://ajuda.b4you.com.br"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bx bx-help-circle" />
                      <span>Ajuda</span>
                    </a>
                  </li>
                </ul>
              </nav>

              <div className="header-right">
                <div className="header-widget" ref={headerWidget}>
                  <a href="#" className="header-widget-icon profile-box">
                    <div className="profile-name cap">
                      {fisrtName(student.full_name)}
                    </div>
                    <AvatarImage src={student.profile_picture} size={40} />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      className="ml-2"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.862 0.861938L11.8048 1.80475L6.00005 7.60949L0.195312 1.80475L1.13812 0.861938L6.00005 5.72387L10.862 0.861938Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                  <div
                    uk-dropdown="pos: top-right ;mode:click"
                    className="dropdown-notifications small"
                  >
                    <ul className="dropdown-user-menu">
                      <li>
                        <NavLink to="/aluno" activeClassName="nav-active">
                          <i className="icon-feather-user"></i>
                          Minha Conta
                        </NavLink>
                      </li>
                      <li className="menu-divider"></li>
                      <li>
                        <NavLink to="/sair" activeClassName="nav-active">
                          <i className="icon-feather-log-out"></i> Sair
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <button
                  aria-label="Abrir Menu"
                  id="btn-mobile"
                  aria-haspopup="true"
                  aria-controls="menu"
                  aria-expanded="false"
                  ref={btnMobile}
                  onClick={toggleMenu}
                >
                  <span id="hamburger"></span>
                </button>
              </div>
            </div>
          </Container>
        </header>
      )}
    </>
  )
}

export default Header
