import ImgCourse from '../../assets/images/course/2.png'
import { Row, Col, Modal, Button, Container } from 'react-bootstrap'
import './style.scss'
import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router-dom'
import Pager from '../../components/Pager'
import api from '../../providers/api'
import CourseCover from '../../components/CourseCover'
import { useProducts } from '../../providers/contextProducts'
import formatDate from '../../utils/formatter'
import Loader from '../../utils/loader'
import { notify } from '../../components/functions'

function PageActivity() {
  const [charges, setCharges] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [refundModal, setRefundModal] = useState(false)
  const [actionModal, setActionModal] = useState(false)
  const [activeItem, setActiveItem] = useState(null)
  const [listingSize, setListingSize] = useState(0)
  const [listingLimit, setListingLimit] = useState(10)
  const { page } = useParams()

  const [confirm, setConfirm] = useState(false)
  const [requesting, setRequesting] = useState(false)

  const listingPage = page === undefined ? 1 : page

  const fetchData = () => {
    api
      .get(`charges?size=${listingLimit}&page=${listingPage - 1}`)
      .then((response) => {
        setCharges(response.data.rows)
        setListingSize(response.data.count)

        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err.response)
      })
  }

  useEffect(() => {
    fetchData()
  }, [page])

  const refundProduct = () => {
    setRequesting(true)
    api
      .post(`charges/cancel-refund`, {
        refund_uuid: activeItem.refund.uuid.uuid
      })
      .then(() => {
        notify({
          message: 'Reembolso solicitado com sucesso',
          type: 'success'
        })
      })
      .catch(() => {
        notify({ message: 'Erro ao solicitar reembolso', type: 'error' })
      })
      .finally(() => {
        setRequesting(false)
        setRefundModal(false)
        setConfirm(false)
        fetchData()
      })
  }

  return (
    <>
      <div className="wrap-page-content">
        <section id="page-activity">
          <Container className="activity page-activity-content">
            <Modal
              show={refundModal}
              centered
              id="modal-style"
              onHide={() => {
                setConfirm(false)
                setRefundModal(false)
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Solicitar cancelamento de Reembolso</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p className="d-block mt-4 mb-4">
                  Tem certeza que deseja cancelar a solicitação de reembolso?
                </p>
                <Row
                  className="align-items-end justify-content-between"
                  style={{ padding: '0 15px' }}
                >
                  <div className="text-left">
                    <Button
                      size="xs"
                      variant="light"
                      className="mt-3 align-right"
                      onClick={() => {
                        setConfirm(false)
                        setRefundModal(false)
                      }}
                    >
                      Agora não
                    </Button>
                  </div>
                  <div className="text-right">
                    {!confirm ? (
                      <Button
                        size="xs"
                        variant="default"
                        onClick={() => {
                          setConfirm(true)
                        }}
                      >
                        Cancelar solicitação
                      </Button>
                    ) : (
                      <Button
                        size="xs"
                        variant="danger"
                        disabled={requesting}
                        onClick={() => refundProduct()}
                      >
                        {!requesting
                          ? 'Confirmar cancelamento'
                          : 'Cancelando reembolso...'}
                      </Button>
                    )}
                  </div>
                </Row>
              </Modal.Body>
            </Modal>
            <Modal
              show={actionModal}
              centered
              id="modal-style"
              onHide={() => {
                setActionModal(false)
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Informações</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <table class="table table-action">
                  <tbody>
                    <tr>
                      <td>ID da venda</td>
                      <td>{activeItem?.uuid}</td>
                    </tr>
                    <tr>
                      <td>Produto</td>
                      <td>{activeItem?.product.name}</td>
                    </tr>
                    <tr>
                      <td>Valor</td>
                      <td>
                        {activeItem?.price.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL'
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td>Pagamento</td>
                      <td>{formatDate(activeItem?.paid_at)}</td>
                    </tr>
                    {activeItem?.product.email && (
                      <tr>
                        <td>Email de Suporte</td>
                        <td>{activeItem.product.email}</td>
                      </tr>
                    )}
                    {activeItem?.product.phone && (
                      <tr>
                        <td>Telefone de Suporte</td>
                        <td>
                          <a
                            href={`https://wa.me/55${activeItem.product.phone.replace(
                              /\s/g,
                              ''
                            )}`}
                            target="_blank"
                          >
                            {activeItem.product.phone}
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {activeItem &&
                    activeItem.refund?.active &&
                    activeItem.refund?.uuid && (
                      <Button
                        size="xs"
                        variant="danger"
                        disabled={false}
                        onClick={() => {
                          setRefundModal(true)
                          setActionModal(false)
                        }}
                        className="btn-refund"
                      >
                        Cancelamento de reembolso
                      </Button>
                    )}
                </div>
              </Modal.Body>
            </Modal>

            <Row>
              <Col>
                <h2>Atividades</h2>
                <div className="uk-card-default uk-card-body data-table">
                  <div className="top-bar">
                    <p className="text">
                      Transações feitas com sua conta de aluno.
                    </p>
                  </div>
                  <div className="table-responsive">
                    <table
                      className={isLoading ? 'table' : 'table table-striped'}
                    >
                      <thead>
                        <tr>
                          <th>
                            <div>
                              <i className="bx bx-image-alt"></i>
                              <span>Capa</span>
                            </div>
                          </th>
                          <th>
                            <div>
                              <i className="bx bx-analyse"></i>
                              <span>Produto</span>
                            </div>
                          </th>
                          <th>
                            <div>
                              <i className="bx bx-tag"></i>
                              <span>Autor</span>{' '}
                            </div>
                          </th>
                          <th>
                            <div>
                              <i className="bx bx-dollar-circle"></i>
                              <span>Valor</span>{' '}
                            </div>
                          </th>
                          <th>
                            <div>
                              <i className="bx bx-calendar-week"></i>
                              <span>Data</span>{' '}
                            </div>
                          </th>
                          <th>
                            <div>
                              <i className="bx bx-refresh"></i>
                              <span>Detalhes</span>{' '}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoading &&
                          charges.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <img
                                  src={item.product.cover}
                                  alt=""
                                  style={{ maxWidth: '50px' }}
                                />
                              </td>
                              <td>{item.product.name}</td>
                              <td>
                                <span style={{ textTransform: 'capitalize' }}>
                                  {item.producer}
                                </span>
                              </td>
                              <td style={{ width: 200, minWidth: 200 }}>
                                {item.price.toLocaleString('pt-br', {
                                  style: 'currency',
                                  currency: 'BRL'
                                })}
                              </td>
                              <td style={{ width: 90, minWidth: 90 }}>
                                {formatDate(item.paid_at)}
                              </td>
                              <td
                                style={{
                                  textAlign: 'center'
                                }}
                              >
                                <Button
                                  size="xs"
                                  variant="primary"
                                  disabled={false}
                                  onClick={() => {
                                    setActiveItem(item)
                                    setActionModal(true)
                                  }}
                                  style={{
                                    padding: 5,
                                    borderRadius: 6
                                  }}
                                >
                                  <i
                                    className="bx bx-file"
                                    style={{ fontSize: 21 }}
                                  />
                                </Button>
                              </td>
                            </tr>
                          ))}
                        {charges.length === 0 && !isLoading && (
                          <tr>
                            <td colSpan={100}>
                              <div className="text-center my-4">
                                Nenhuma atividade encontrada...
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
              <Col xs={12} className="mt-4">
                <Pager
                  size={listingSize}
                  limit={listingLimit}
                  active={listingPage}
                  url={'/atividades'}
                />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  )
}

export default PageActivity
