import { useState, useEffect } from 'react'
import { BrowserRouter as Router, useHistory } from 'react-router-dom'
import api from '../../providers/api'
import Loading from '../../providers/Loading'

function PageLogin() {
  const history = useHistory()

  useEffect(() => {
    api
      .get('auth/logout')
      .then((response) => {
        history.push('/acessar')
      })
      .catch((err) => {
        history.push('/acessar')
      })
  })

  return (
    <>
      <Loading />
    </>
  )
}

export default PageLogin
