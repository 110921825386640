import { useState } from 'react'
import { Row, Col, Modal, Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { notify } from '../../components/functions'
import api from '../../providers/api'

export const ModalChangeCard = ({
  showModal,
  setShowModal,
  uuidSubscription,
  fetchData
}) => {
  const [requesting, setRequesting] = useState(false)
  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onChange'
  })
  const { isValid } = formState

  const onSubmit = (data) => {
    const card_number = data.card_number.replace(/ /g, '')
    data.card_number = card_number
    setRequesting(true)
    api
      .put(`/subscriptions/${uuidSubscription}/card`, data)
      .then((response) => {
        notify({ message: 'Dados salvos com sucesso', type: 'success' })
        fetchData()
        setShowModal(false)
      })
      .catch((err) => {
        notify({ message: 'Falha ao salvar os dados', type: 'error' })
      })
      .finally(() => setRequesting(false))
  }
  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        id="modal-style"
      >
        <Modal.Header closeButton>
          <Modal.Title>Trocar cartão de crédito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <form action="put" onSubmit={handleSubmit(onSubmit)}>
                <Row className="input-list">
                  <Col md={12}>
                    <Form.Group>
                      <label>Número do Cartão</label>
                      <InputMask
                        type="text"
                        name="card_number"
                        placeholder="xxxx xxxx xxxx xxxx"
                        ref={register({
                          required: 'Campo obrigatório.',
                          validate: (value) => {
                            const str = value.replace(/\D/g, '')
                            return (
                              str.length > 15 ||
                              'Seu Cartão deve conter 16 dígitos.'
                            )
                          }
                        })}
                        className={errors.card_number && 'is-invalid'}
                        mask="9999 9999 9999 9999"
                        maskChar={null}
                      />
                      <div className="form-error">
                        {errors.card_number && (
                          <span>{errors.card_number.message}</span>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group>
                      <label>Código de segurança</label>

                      <InputMask
                        type="text"
                        name="cvv"
                        placeholder="xxx"
                        ref={register({
                          required: 'Campo obrigatório.',
                          validate: (value) => {
                            const str = value.replace(/\D/g, '')
                            return (
                              str.length > 2 ||
                              'Seu código de segurança deve conter 3 digitos.'
                            )
                          }
                        })}
                        mask="999"
                        className={errors.cvv && 'is-invalid'}
                      />
                      <div className="form-error">
                        {errors.cvv && <span>{errors.cvv.message}</span>}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group>
                      <label>Data de vencimento</label>
                      <InputMask
                        type="text"
                        name="expiration_date"
                        placeholder="mm/aaaa"
                        ref={register({
                          required: 'Campo obrigatório.'
                        })}
                        mask="99/9999"
                        className={errors.expiration_date && 'is-invalid'}
                      />
                      <div className="form-error">
                        {errors.expiration_date && (
                          <span>{errors.expiration_date.message}</span>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <label>Nome impresso no cartão</label>
                      <input
                        type="text"
                        name="card_holder"
                        placeholder="Nome"
                        ref={register({
                          required: 'Campo obrigatório.'
                        })}
                        className={errors.card_holder && 'is-invalid'}
                      />
                      <div className="form-error">
                        {errors.card_holder && (
                          <span>{errors.card_holder.message}</span>
                        )}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right">
                    <Button
                      className="btn btn-default small mt-2 align-right"
                      type="submit"
                      variant="default"
                      disabled={requesting || !isValid}
                    >
                      {requesting ? 'Salvando...' : 'Salvar'}
                    </Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}
