import { useState, useEffect } from 'react'
import { Col, Row, Button, Form } from 'react-bootstrap'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import jsonListOfBanks from '../../json/jsonListOfBanks'
import api from '../../providers/api'
import { notify } from '../../components/functions'

export const BankAccountForm = () => {
  const [requesting, setRequesting] = useState(false)

  const {
    register,
    setValue,
    handleSubmit,
    errors,
    control,
    formState,
    reset,
    getValues
  } = useForm({ mode: 'onChange' })
  const { isValid } = formState

  useEffect(() => {
    api
      .get('/student/profile')
      .then((response) => {
        const data = response.data.bank
        reset(data)
      })
      .catch(() => {})
  }, [])

  const onSubmit = (data) => {
    setRequesting(true)
    api
      .put('/student/bank-account', data)
      .then((response) => {
        notify({ message: 'Dados salvos com sucesso', type: 'success' })
        setRequesting(false)
      })
      .catch((err) => {
        notify({ message: 'Falha ao salvar os dados', type: 'error' })
        console.log(err.response)
        setRequesting(false)
      })
  }

  return (
    <>
      <form action="" onSubmit={handleSubmit(onSubmit)}>
        <div className="sub-title">
          <i className="bx bxs-bank"></i>

          <h2>Conta Bancária</h2>
        </div>
        <Row className="input-list">
          <Col md={12}>
            <Form.Group>
              <Controller
                name="bank_code"
                className="select"
                control={control}
                render={({ onChange, value, ref }) => (
                  <Select
                    inputRef={ref}
                    placeholder="Selecione o banco..."
                    isMulti={false}
                    options={jsonListOfBanks}
                    value={jsonListOfBanks.find((c) => c.value === value)}
                    onChange={(val) => onChange(val.value)}
                  />
                )}
              />
            </Form.Group>
          </Col>

          <Col md={12}>
            <Form.Group>
              <input
                name="account_agency"
                type="text"
                placeholder="Agência"
                ref={register({ required: true })}
                className={
                  !errors.account_agency ? 'uk-input' : 'uk-input is-invalid'
                }
                maxLength="4"
              />
            </Form.Group>
          </Col>
          <Col md={12}>
            <Form.Group>
              <input
                name="account_number"
                type="text"
                placeholder="Conta (com dígito)"
                ref={register({ required: true })}
                className={
                  !errors.account_number ? 'uk-input' : 'uk-input is-invalid'
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="text-left">
            <button
              size="sm"
              onClick={handleSubmit(onSubmit)}
              className="btn btn-default w-100"
              disabled={!isValid || requesting}
            >
              {!requesting ? 'Salvar conta bancária' : 'salvando...'}
            </button>
          </Col>
        </Row>
      </form>
    </>
  )
}
