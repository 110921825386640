import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './style.scss'
import ProductCardComponent from '../../components/ProductCardComponent'

function PageEbooks({ ebooks }) {
  return (
    <>
      {ebooks && (
        <div className="products container">
          <Row>
            <Col>
              <section id="page-ebooks">
                {ebooks.length > 0 && (
                  <>
                    <h2>Meus Ebooks</h2>
                    <div className="list-products">
                      {ebooks.map((product) => (
                        <Link
                          to={`/ebooks/${product.slug}/${product.uuid}`}
                          className="link-course"
                          key={product.uuid}
                        >
                          <ProductCardComponent course={product} />
                        </Link>
                      ))}
                    </div>
                  </>
                )}
              </section>
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default PageEbooks
