/* eslint-disable implicit-arrow-linebreak */
import { useState, useEffect } from 'react'
import { Row, Col, Modal, Form, Button, Nav } from 'react-bootstrap'
import './style.scss'
import Switch from 'react-switch'
import { useHistory, useParams, Link } from 'react-router-dom'
import api from '../../providers/api'
import VideoPlayer from './VideoPlayer'
import { notify } from '../../components/functions'
import Loader from '../../utils/loader'
import WhatsApp from '../courses/whatsapp.js'
import Header from '../../components/Header.js'
import Footer from '../../components/Footer.js'
import Attachments from './attachments'

function PageLearn() {
  const [show, setShow] = useState(false)
  const [showViewNotes, setShowViewNotes] = useState(false)
  const { slug, uuidCourse, uuidLesson } = useParams()
  const [modules, setModules] = useState([])
  const [course, setCourse] = useState(null)
  const [currentLesson, setCurrentLesson] = useState(null)
  const [backUrl, setBackUrl] = useState(null)
  const [checked, setChecked] = useState(null)
  const [autoCheck, setAutoCheck] = useState(false)
  const [loading, setLoading] = useState(true)
  const [overlay, setOverlay] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [videoProgress, setVideoProgress] = useState(0)
  const [waitPeriodNextLesson] = useState(5)
  const [countdown, setCountdown] = useState(waitPeriodNextLesson)
  const [videoEnded, setVideoEnded] = useState(false)
  const [intervalCountDown, setIntervalCountDown] = useState(null)
  const [showNotes, setShowNotes] = useState(false)
  const [currentNote, setCurrentNote] = useState(null)
  const [notes, setNotes] = useState([])
  const [titleField, setTitleField] = useState('')
  const [noteField, setNoteField] = useState('')

  const [selectedModule, setSelectedModule] = useState(null)
  const [totalLessons, setTotalLessons] = useState(null)
  const [completedLessons, setCompletedLessons] = useState(null)
  const [progressPercentage, setProgressPercentage] = useState(null)

  const [isCurrentLessonWatched, setIsCurrentLessonWatched] = useState(false)

  const [lessonClasses, setLessonClasses] = useState({})
  const [firstLoad, setFirstLoad] = useState(true)

  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  const history = useHistory()

  const handleClose = () => setShow(false)
  const handleCloseNote = () => setShowViewNotes(false)

  const handleShow = () => setShow(true)
  const handleShowViewNote = () => setShowViewNotes(true)

  const handleShowNotes = () => {
    setShowNotes(!showNotes)
  }

  const updateHistory = (lessonId, done) => {
    const updatedClasses = { ...lessonClasses }
    updatedClasses[lessonId] = done ? 'watched' : ''
    setLessonClasses(updatedClasses)

    const fields = {
      lesson_id: lessonId,
      time: 0,
      done
    }

    api
      .put(`/lessons/history`, fields)
      .then((r) => {})
      .catch((err) => {})
      .finally(() => {
        setFirstLoad(false)
      })
  }

  useEffect(() => {
    if (!firstLoad) {
      updateHistory(uuidLesson, checked)
    }
  }, [checked])

  const getNotes = () => {
    api
      .get(`/lessons/${uuidLesson}/notes`)
      .then((response) => {
        setNotes(response.data)
      })
      .catch((err) => err.data)
  }

  const deleteNote = () => {
    api
      .delete(`/lessons/${uuidLesson}/notes/${currentNote.uuid}`)
      .then(() => {
        getNotes()
        notify({
          message: 'Nota removida com sucesso',
          type: 'success'
        })
        setShowViewNotes(false)
      })
      .catch((err) => {
        notify({ message: 'Erro ao remover nota', type: 'error' })
      })
  }

  const createNotes = (e) => {
    e.preventDefault()
    const fields = {
      title: titleField,
      note: noteField
    }

    api
      .post(`/lessons/${uuidLesson}/notes`, fields)
      .then(() => {
        getNotes()
        notify({
          message: 'Nota criada com sucesso',
          type: 'success'
        })
        setShow(false)
      })
      .catch((err) => {
        notify({ message: 'Erro ao criar nota', type: 'error' })
      })
  }

  const goToNextLesson = () => {
    const allLessons = selectedModule.lessons
    const currentIndex = allLessons.findIndex(
      (lesson) => lesson.uuid === uuidLesson
    )
    if (currentIndex === -1) {
      return
    }
    const nextLessonInModule = allLessons[currentIndex + 1]
    if (nextLessonInModule) {
      history.push(`/aprenda/${slug}/${uuidCourse}/${nextLessonInModule.uuid}`)
    } else {
      const currentModuleIndex = modules.findIndex(
        (module) => module.uuid === selectedModule.uuid
      )
      const nextModule = modules[currentModuleIndex + 1]
      if (nextModule) {
        history.push(
          `/aprenda/${slug}/${uuidCourse}/${nextModule.lessons[0].uuid}`
        )
      } else {
        history.push(`/cursos/${slug}/${uuidCourse}`)
      }
    }
  }
  const goToNextModule = () => {
    const currentModuleIndex = modules.findIndex(
      (module) => module.uuid === selectedModule.uuid
    )
    const nextModule = modules[currentModuleIndex + 1]
    if (nextModule) {
      history.push(
        `/aprenda/${slug}/${uuidCourse}/${nextModule.lessons[0].uuid}`
      )
    } else {
      history.push(`/cursos/${slug}/${uuidCourse}`)
    }
  }

  const goToPrevLesson = () => {
    if (currentLesson.prev_lesson) {
      const prevLessonUrl = `/aprenda/${course.slug}/${course.uuid}/${currentLesson.prev_lesson.uuid}`
      history.push(prevLessonUrl)
    }
  }

  useEffect(() => {
    if (firstLoad) {
      setLoading(true)
      setFirstLoad(false)
    } else {
      setOverlay(true)
    }

    api.get(`/products/${uuidCourse}/membership-plugins`).then((r) => {
      const whatsPlugin = r.data.plugins.find((p) => p.type.key === 'whatsapp')
      if (whatsPlugin) {
        setPhone(whatsPlugin.settings.phone)
        setMessage(whatsPlugin.settings.message)
      }
    })

    api
      .get(`/products/${uuidCourse}/${uuidLesson}`)
      .then((response) => {
        const currentLesson = response.data.current_lesson
        const product = response.data.product

        setCourse(product)
        setCurrentLesson(currentLesson)
        let selectedModule, modules
        if (product.anchor_view) {
          const anchors = product.anchors
          modules = anchors.map((anchor) => anchor.modules).flat()
          selectedModule = modules.find(
            (m) => m.uuid === currentLesson.module_id
          )
          const selectedAnchor = anchors.find((anchor) =>
            anchor.modules.some((mod) => mod.uuid === selectedModule.uuid)
          )

          const selectedModuleCurrentAnchor = selectedAnchor.modules.find(
            (m) => m.uuid === currentLesson.module_id
          )

          setModules(selectedAnchor.modules)
          selectedModule = selectedModuleCurrentAnchor
        } else {
          modules = product.modules
          selectedModule = modules.find(
            (m) => m.uuid === currentLesson.module_id
          )

          setModules(modules)
        }
        setSelectedModule(selectedModule)
        const totalLessons = selectedModule.lessons.length
        const completedLessons = selectedModule.lessons.filter(
          (lesson) => lesson.history.done
        ).length
        const progressPercentage = Math.round(
          (completedLessons / totalLessons) * 100
        )
        setTotalLessons(totalLessons)
        setCompletedLessons(completedLessons)
        setProgressPercentage(progressPercentage)
        setAutoCheck(false)
        setCountdown(waitPeriodNextLesson)
        setVideoEnded(false)

        const lessonHistory =
          response.data.current_lesson.history.done !== false
        setChecked(lessonHistory)
        setIsCurrentLessonWatched(lessonHistory)

        setBackUrl(`/cursos/${product.slug}/${product.uuid}`)

        if (intervalCountDown) {
          clearInterval(intervalCountDown)
          setIntervalCountDown(null)
        }

        setLoading(false)
        setOverlay(false)
      })
      .catch((err) => {})
    getNotes(uuidLesson)
  }, [uuidLesson])

  useEffect(() => {
    if (videoEnded === true) {
      if (intervalCountDown === null) {
        if (currentLesson.next_lesson) {
          setIntervalCountDown(
            setInterval(() => {
              setCountdown((value) => {
                if (value > 0) {
                  return value - 1
                }
                clearInterval(intervalCountDown)
                goToNextLesson()
                return 0
              })
            }, 1000)
          )
        }
      }
    }
  }, [videoEnded])

  useEffect(() => {
    if (videoProgress > 0.9) {
      if (autoCheck === false) {
        if (checked === false) {
          // marcar aula como realizada
          setChecked(true)
          setAutoCheck(true)
        }
      }
    }
  }, [videoProgress])

  const handleChange = () => {
    const newValue = !checked
    setChecked(newValue)
    setIsCurrentLessonWatched(newValue)
    updateHistory(uuidLesson, newValue)
    if (checked === false) {
      const progressPercentage = Math.round(
        ((completedLessons + 1) / totalLessons) * 100
      )
      setCompletedLessons(completedLessons + 1)
      setProgressPercentage(progressPercentage)
    } else {
      const progressPercentage = Math.round(
        ((completedLessons - 1) / totalLessons) * 100
      )
      setCompletedLessons(completedLessons - 1)
      setProgressPercentage(progressPercentage)
    }
  }

  return (
    <div id="page-video">
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Modal
            show={show}
            centered
            onHide={handleClose}
            className="modal-create-notes"
          >
            <Modal.Body>
              <form onSubmit={createNotes}>
                <div className="post">
                  <div onClick={handleClose} className="d-flex pointer">
                    <i
                      className="bx bx-x ml-auto"
                      style={{ fontSize: 22, cursor: 'pointer' }}
                    ></i>
                  </div>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Control
                          className="field-title"
                          name="title"
                          type="text"
                          placeholder="Escreva um título..."
                          onChange={(e) => {
                            setTitleField(e.target.value)
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="row-note">
                    <Col>
                      <Form.Group>
                        <textarea
                          className="field-note"
                          name="note"
                          type="text"
                          placeholder="Mensagem..."
                          rows="23"
                          onChange={(e) => {
                            setNoteField(e.target.value)
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="row-btn mt-4">
                    <Button
                      className="btn-close"
                      onClick={() => handleClose(true)}
                    >
                      Fechar
                    </Button>
                    <Button
                      className="btn btn-primary "
                      type="submit"
                      disabled={titleField === '' || noteField === ''}
                    >
                      <span>Criar</span>
                      <i className="bx bx-note"></i>
                    </Button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>

          {currentNote && (
            <Modal
              show={showViewNotes}
              centered
              onHide={handleCloseNote}
              className="modal-notes"
            >
              <Modal.Body>
                <div className="post">
                  <div onClick={handleCloseNote} className="d-flex pointer">
                    <i
                      className="bx bx-x ml-auto"
                      style={{ fontSize: 22, cursor: 'pointer' }}
                    ></i>
                  </div>
                  <Row>
                    <Col>
                      <h2>{currentNote.title}</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>{currentNote.note}</p>
                    </Col>
                  </Row>
                  <div className="row-btn mt-4">
                    <Button
                      className="btn-close"
                      onClick={() => setShowViewNotes(false)}
                    >
                      Fechar
                    </Button>
                    <Button onClick={deleteNote} variant="danger">
                      Excluir anotação
                    </Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}

          <section id="page-learn">
            {overlay && (
              <div id="overlay">
                <i className="bx bx-loader-alt bx-spin"></i>
                <div>Aguarde...</div>
              </div>
            )}
            {downloading && (
              <div id="overlay">
                <i className="bx bx-loader-alt bx-spin"></i>
                <div>Aguarde...</div>
              </div>
            )}
            <div className={`notes ${showNotes ? 'show' : ''}`}>
              <div className="header">
                <i
                  className="bx bx-x"
                  style={{
                    fontSize: '26px',
                    color: '#fff',
                    fontWeight: 200,
                    marginRight: '8px',
                    cursor: 'pointer'
                  }}
                  onClick={() => handleShowNotes()}
                ></i>

                <h2>Minhas notas</h2>
              </div>

              <div className="list-notes">
                {notes.map((note) => (
                  <div
                    className="note"
                    key={note.uuid}
                    onClick={() => {
                      setCurrentNote(note)
                      handleShowViewNote()
                    }}
                  >
                    <h3 className="head">{note.title}</h3>
                    <p className="text">{note.note}</p>
                    <svg
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="13" cy="13" r="12.5" stroke="#B2E9E0" />
                      <path
                        d="M13 16.25C13.9666 16.25 14.75 17.0344 14.75 18C14.75 18.9656 13.9666 19.75 13 19.75C12.0334 19.75 11.25 18.9656 11.25 18C11.25 17.0344 12.0334 16.25 13 16.25ZM13 11.25C13.9666 11.25 14.75 12.0344 14.75 13C14.75 13.9656 13.9666 14.75 13 14.75C12.0334 14.75 11.25 13.9656 11.25 13C11.25 12.0344 12.0334 11.25 13 11.25ZM13 9.75C12.0334 9.75 11.25 8.96563 11.25 8C11.25 7.03344 12.0334 6.25 13 6.25C13.9666 6.25 14.75 7.03344 14.75 8C14.75 8.96563 13.9666 9.75 13 9.75Z"
                        fill="#4DD0BB"
                      />
                    </svg>
                  </div>
                ))}
              </div>
              <div className="btn btn-primary" onClick={handleShow}>
                <span>Criar anotação</span>
                <i className="bx bx-note"></i>
              </div>
            </div>
            <div id="container-video" style={{ marginTop: 72 }}>
              <div id="video-container">
                <div id="video">
                  <VideoPlayer
                    url={currentLesson.videoID}
                    setVideoEnded={setVideoEnded}
                    setVideoProgress={setVideoProgress}
                  />
                </div>
                <div className="control-bar">
                  <div className="infos">
                    <h2>{currentLesson.title}</h2>
                  </div>
                  <div className="wrap-check d-flex justify-content-between w-100 align-items-center flex-wrap">
                    <label className="check-box">
                      <Switch
                        onChange={handleChange}
                        checked={checked}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onColor="#47E6B1"
                        onHandleColor="#47E6B1"
                        handleDiameter={20}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.0)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.0)"
                        height={20}
                        width={40}
                        className={`react-switch ${
                          checked ? `checked` : `not-checked`
                        }`}
                        id="material-switch"
                      />
                      <div className="text">Marcar como assistido</div>
                    </label>
                    <div
                      className="control-button"
                      onClick={() => goToNextLesson()}
                    >
                      <div>Próximo</div>
                      <i className="bx bx-right-arrow-alt"></i>
                    </div>
                  </div>
                </div>
                <div className="content-text">
                  <TabContentText
                    currentLesson={currentLesson}
                    downloading={downloading}
                    setDownloading={setDownloading}
                  />
                </div>
              </div>
              <div id="right-sidebar">
                <div className="info-module">
                  <h6 style={{ marginBottom: 0, fontWeight: 400 }}>Módulo</h6>
                  <h3>{selectedModule.title}</h3>

                  <div className="wrap-progress">
                    <progress value={progressPercentage} max="100"></progress>
                    <div className="progress-text">
                      <div>Progresso: {progressPercentage}%</div>
                      <div>
                        Aulas: {completedLessons}/{totalLessons}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bar">
                  {selectedModule.lessons.map((lesson) => {
                    const isCurrentLesson = lesson.uuid === uuidLesson
                    const lessonStatus = lesson.history.done
                      ? 'complete'
                      : 'pending'
                    const statusClass = isCurrentLesson
                      ? 'current'
                      : lessonStatus
                    const watchedClass =
                      isCurrentLesson && lesson.history.done
                        ? 'watched'
                        : 'current'

                    return (
                      <Link
                        to={`/aprenda/${slug}/${uuidCourse}/${lesson.uuid}`}
                        className={`lesson-title ${statusClass} ${
                          isCurrentLesson && isCurrentLessonWatched && `watched`
                        }`}
                        key={lesson.uuid}
                      >
                        <div className={`lesson-item ${statusClass}`}>
                          <div className={`status-indicator ${statusClass}`}>
                            {statusClass === 'complete' && (
                              <i className="circle bx bx-check complete"></i>
                            )}
                            {statusClass === 'current' && (
                              <i className="circle bx bxs-circle current"></i>
                            )}
                            {statusClass === 'pending' && (
                              <i className="circle pending"></i>
                            )}
                            <div className={`line ${statusClass}`}></div>
                          </div>
                          <span className="text">{lesson.title}</span>
                        </div>
                      </Link>
                    )
                  })}
                  <div
                    className="control-button"
                    onClick={() => goToNextModule()}
                  >
                    <div>Próximo módulo</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Footer />
            </div>

            <WhatsApp phone={phone} message={message} />
          </section>
        </>
      )}
    </div>
  )
}

export default PageLearn

export const TabContentText = ({
  currentLesson,
  downloading,
  setDownloading
}) => {
  const [navSelected, setNavSelected] = useState('0')

  const handleSelect = (eventKey) => {
    setNavSelected(eventKey)
  }

  return (
    <>
      <Nav variant="pills" activeKey={navSelected} onSelect={handleSelect}>
        <Nav.Item>
          <Nav.Link eventKey="0" className="">
            Descrição
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="1">Arquivos</Nav.Link>
        </Nav.Item>
        {/*  <Nav.Item>
          <Nav.Link eventKey="2">Comentários</Nav.Link>
        </Nav.Item> */}
      </Nav>
      <div className="content">
        {navSelected === '0' && (
          <>
            {currentLesson &&
            currentLesson.description &&
            currentLesson.description.replace(/<\/?[^>]+(>|$)/g, '').length >
              0 ? (
              <>
                <div className="description">
                  {currentLesson.description.replace(/<\/?[^>]+(>|$)/g, '')
                    .length === 1 ? (
                    <div className="html-description">
                      <span style={{ opacity: '0.5' }}>Sem descrição.</span>
                    </div>
                  ) : (
                    <div
                      className="html-description"
                      dangerouslySetInnerHTML={{
                        __html: currentLesson.description
                      }}
                    ></div>
                  )}
                </div>
              </>
            ) : (
              <div className="description">
                <div className="html-description">
                  <span style={{ opacity: '0.5' }}>Sem descrição.</span>
                </div>
              </div>
            )}
          </>
        )}
        {navSelected === '1' && (
          <>
            {currentLesson && currentLesson.attachments.length > 0 ? (
              <>
                <Attachments
                  files={currentLesson.attachments}
                  downloading={downloading}
                  setDownloading={setDownloading}
                />
              </>
            ) : (
              <div className="html-description">
                <span style={{ opacity: '0.5' }}>Sem arquivos.</span>
              </div>
            )}
          </>
        )}
        {navSelected === '2' && (
          <div className="comments">
            <div className="card-comment">
              <div className="top">
                <div className="user">
                  <div className="img">
                    <img src="https://i.ibb.co/jGqsggB/Oval.png" />
                  </div>
                  <div className="name">Allan Frank</div>
                  <div className="date">5 dias atrás</div>
                </div>
                <div className="right">
                  <div className="icon-right">
                    <i class="bx bx-reply"></i>
                  </div>
                  <div className="text">Resposta</div>
                </div>
              </div>
              <div className="text-comment">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </div>
            </div>
            <div className="card-response">
              <div className="img">
                <img src="https://i.ibb.co/jGqsggB/Oval.png" />
              </div>
              <div className="text-comment">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s
              </div>
              <div className="btn-response">Responder</div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
