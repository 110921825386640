import React, { createContext, useContext, useState } from 'react'

export const StudentContext = createContext()
export const useStudent = () => useContext(StudentContext)

const StudentProvider = (props) => {
  const [student, setStudent] = useState(null)

  return <StudentContext.Provider value={{ student, setStudent }} {...props} />
}

export default StudentProvider
