// import './App.css';
import logo from '../assets/images/sixbase/logo-horizontal-2.png'

function Footer() {
  return (
    <>
      <footer>
        <a
          href="https://b4you.com.br"
          target="_blank"
          className="uk-logo"
          rel="noreferrer"
          style={{ zIndex: 99999 }}
        >
          <img src={logo} alt="Logo B4you" />
        </a>
      </footer>
    </>
  )
}

export default Footer
