import React, { createContext, useContext, useState, useMemo } from 'react'

export const ProductsContext = createContext()
export const useProducts = () => useContext(ProductsContext)

const ProductsProvider = (props) => {
  const [coursesValue, setCoursesValue] = useState([])
  const [coursesViewedValue, setCoursesViewedValue] = useState(null)
  const [ebooksValue, setEbooksValue] = useState([])

  const value = useMemo(
    () => ({
      coursesContext: { coursesValue, setCoursesValue },
      coursesViewedContext: { coursesViewedValue, setCoursesViewedValue },
      ebooksContext: {
        ebooksValue,
        setEbooksValue
      }
    }),
    [coursesValue, coursesViewedValue, ebooksValue]
  )

  return <ProductsContext.Provider value={value} {...props} />
}

export default ProductsProvider
