import React from 'react'
import placeholder from '../assets/images/placeholder-ebook.png'

const EbookCover = ({ src }) => {
  const image = src || placeholder

  return <img className="ebook-cover shadow" src={image} />
}

export default EbookCover
