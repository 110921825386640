import React from 'react'
import Footer from '../components/Footer'
import ProductPageComponent from '../components/ProductPageComponent'
import Header from '../components/Header'

const LayoutPageProduct = ({ children }) => {
  return (
    <div id="layout-page-product">
      <Header />
      <ProductPageComponent />
      {children}
      <Footer />
    </div>
  )
}

export default LayoutPageProduct
