import React from 'react'
import formatDate from '../utils/formatter'
import sixbaseTemplate312 from '../assets/images/sixbase-template-312.jpg'
import sixbaseTemplate512 from '../assets/images/sixbase-template-512.jpg'

const ProductCardComponent = ({
  course,
  activeCourse,
  releaseDate,
  isAnchor = false,
  style = {}
}) => {
  const showTitle = !isAnchor

  return (
    <>
      {course ? (
        course.type === 'video' ? (
          <div className="product-card-component">
            <div className="img-wrap">
              {course.cover ? (
                <img
                  src={course.cover}
                  alt="Imagem do Produto"
                  style={{ borderRadius: showTitle ? '8px 8px 0 0' : '8px' }}
                />
              ) : (
                <img
                  src={sixbaseTemplate312}
                  alt="Imagem do Produto"
                  style={{ borderRadius: showTitle ? '8px 8px 0 0' : '8px' }}
                />
              )}
              {/*   {course.progress * 100 !== 0 && (
                <progress
                  id="progress-bar"
                  value={course.progress * 100}
                  max="100"
                />
              )} */}
            </div>
            {showTitle && (
              <div className="info">
                <div className="duration">
                  <i className="bx bx-time-five"></i>
                  <span className="text">{course.duration}</span>
                </div>
                <div className="category">Curso: {course.category.label}</div>
                <h3>{course.name}</h3>
                <div className="bottom">
                  <div className="lessons">
                    <i className="bx bx-movie"></i>
                    <span>
                      {course.total_lessons}
                      {course.total_lessons === 1 ? ' aula' : ' aulas'}
                    </span>
                  </div>
                  <div className="button">
                    <i className="bx bx-play"></i>
                    <span>
                      {course.progress * 100 === 100
                        ? 'Continuar'
                        : 'Continuar'}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="product-card-component">
            {course.cover ? (
              <img
                src={course.cover}
                alt="Imagem do Produto"
                style={{ borderRadius: showTitle ? '8px 8px 0 0' : '8px' }}
              />
            ) : (
              <div
                className="card-course"
                style={{
                  borderRadius: showTitle ? '8px 8px 0 0' : '8px'
                }}
              >
                {course.name}
              </div>
            )}
            {showTitle && (
              <div className="info">
                <div className="category">Ebook: {course.category.label}</div>
                <h3>{course.name}</h3>
                <div className="bottom">
                  <div className="lessons">
                    <i className="bx bx-book-bookmark"></i>
                  </div>
                  <div className="button">
                    <i className="bx bx-play"></i>
                    <span>Acessar</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      ) : (
        activeCourse && (
          <div className="product-card-component" style={style}>
            {activeCourse.cover ? (
              <img
                src={activeCourse.cover}
                alt="Imagem do Produto"
                style={{ borderRadius: showTitle ? '8px 8px 0 0' : '8px' }}
              />
            ) : (
              <div
                className="card-course card-course-lg"
                style={{
                  borderRadius: showTitle ? '8px 8px 0 0' : '8px'
                }}
              >
                {activeCourse.title}
              </div>
            )}
            {!isAnchor && (
              <div className="info">
                <h3>{activeCourse.title}</h3>

                <div className="bottom">
                  <div className="lessons">
                    <i className="bx bx-movie"></i>
                    <span>
                      {activeCourse.lessons.length}
                      {activeCourse.lessons.length === 1 ? ' aula' : ' aulas'}
                    </span>
                  </div>
                </div>
                {releaseDate && (
                  <div className={`release-date show-title`}>
                    <div>Libera em: {formatDate(releaseDate)}</div>
                  </div>
                )}
              </div>
            )}
            {isAnchor && releaseDate && (
              <div className={`release-date`}>
                <div>Libera em: {formatDate(releaseDate)}</div>
              </div>
            )}
          </div>
        )
      )}
    </>
  )
}

export default ProductCardComponent
