import { ToastContainer } from 'react-toastify'
import Routes from './providers/Routes'
import StudentProvider from './providers/contextStudent'
import ProductsProvider from './providers/contextProducts'
import './assets/css/night-mode.css'
import './assets/css/framework.css'
import './assets/css/bootstrap.css'
import './assets/css/icons.css'
import './assets/css/custom.scss'
import 'react-toastify/dist/ReactToastify.css'
import './utils/colors/colors.scss'
import './assets/css/style.css'

function App() {
  return (
    <StudentProvider>
      <ProductsProvider>
        <Routes />
        <ToastContainer theme="dark" />
      </ProductsProvider>
    </StudentProvider>
  )
}

export default App
