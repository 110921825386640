import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import LayoutMain from '../pages/layout-main'
import LayoutPageProduct from '../pages/layout-page-product'
import { useStudent } from './contextStudent'
import api from './api'

const PrivateRoute = ({ component: Component, path, exact, hideLayout }) => {
  const { student, setStudent } = useStudent()

  useEffect(() => {
    if (!student) {
      api
        .get('auth/me')
        .then((response) => {
          if (response.data !== null) {
            setStudent(response.data)
          }
        })
        .catch(() => {
          window.history.pushState(null, null, '/acessar')
        })
    }
  }, [])

  const route = (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        return student ? <Component {...props} /> : <Redirect to="/acessar" />
      }}
    />
  )
  if (hideLayout === 'page-product') {
    return <LayoutPageProduct>{route}</LayoutPageProduct>
  }
  if (hideLayout === 'true') {
    return route
  }
  return <LayoutMain>{route}</LayoutMain>
}

export default PrivateRoute
