import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import PageProfile from '../pages/profile'
import PageCourseSingle from '../pages/courses/single'
import PageEbooks from '../pages/ebooks'
import PageEbookSingle from '../pages/ebooks/single'
import PageSubscriptions from '../pages/subscriptions'
import PageActivity from '../pages/activity'
import PageLearn from '../pages/learn'

import PageLogin from '../pages/auth/PageLogin'
import PageLogout from '../pages/auth/PageLogout'

import PrivateRoute from './PrivateRoute'

import PageCancel from '../pages/cancel'
import PagePassword from '../pages/auth/PagePassword'
import PageHome from '../pages/home'
import { PageProducts } from '../pages/products'

import Error404 from '../pages/404/Error404'

const Routes = () => {
  return (
    <>
      <div id="wrapper">
        <Router>
          <Switch>
            <Route path="/acessar/:token?">
              <PageLogin />
            </Route>
            <Route path="/sair">
              <PageLogout />
            </Route>
            <Route path="/cadastrar-senha/:token/:type?">
              <PagePassword />
            </Route>

            <PrivateRoute component={PageEbooks} path="/ebooks/:page?" exact />
            <PrivateRoute
              component={PageEbookSingle}
              path="/ebooks/:slug/:uuid"
              exact
            />
            <PrivateRoute
              component={PageActivity}
              path="/atividades/:page?"
              exact
            />
            <PrivateRoute
              component={PageSubscriptions}
              path="/assinaturas/:page?"
              exact
            />
            <PrivateRoute component={PageProfile} path="/aluno" exact />
            <PrivateRoute
              component={PageProfile}
              path="/aluno/cobranca"
              exact
            />

            <PrivateRoute
              component={PageCourseSingle}
              path="/cursos/:slug/:uuid"
              hideLayout={'page-product'}
              exact
            />

            <PrivateRoute component={PageCancel} path="/cancelar/:uuid" exact />

            <PrivateRoute
              component={PageLearn}
              path="/aprenda/:slug/:uuidCourse/:uuidLesson"
              hideLayout={'true'}
              exact
            />

            <PrivateRoute component={PageProducts} path="/produtos" exact />

            <PrivateRoute component={PageHome} path="/" exact />

            <PrivateRoute
              component={Error404}
              path="*"
              hideLayout={'true'}
              exact
            />
          </Switch>
        </Router>
      </div>
    </>
  )
}

export default Routes
