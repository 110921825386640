import { Row, Col, Modal, Button, Container } from 'react-bootstrap'
import './style.scss'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../providers/api'
import Pager from '../../components/Pager'
import { ModalChangeCard } from './ModalChangeCard'
import ModalCancel from './ModalCancel'
import formatDate from '../../utils/formatter'
import HomeComponent from '../../components/HomeComponent'

function PageSubscriptions() {
  const [subscriptions, setSubscriptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [listingSize, setListingSize] = useState(0)
  const [listingLimit, setListingLimit] = useState(5)
  const [showModal, setShowModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showWarningModalPix, setShowWarningModalPix] = useState(false)
  const [uuidSubscription, setUuidSubscription] = useState(null)
  const [activeSubscription, setActiveSubscription] = useState(null)
  const { page } = useParams()

  const listingPage = page === undefined ? 1 : page

  const fetchData = () => {
    api
      .get(`subscriptions?size=${listingLimit}&page=${listingPage - 1}`)
      .then((response) => {
        setSubscriptions(response.data.rows)
        setListingSize(response.data.count)
        setIsLoading(false)
      })
      .catch((err) => {})
  }

  useEffect(() => {
    fetchData()
  }, [page])

  const BadgeDataTable = ({ variant, name }) => (
    <div id="BadgeDataTable" className={variant}>
      <span className={variant}>{name}</span>
    </div>
  )

  return (
    <>
      <div className="wrap-page-content">
        <div id="subscription-page">
          <Container className="subscriptions page-subscription-content">
            <Modal show={false} centered>
              <Modal.Header>
                <h5 className="mb-0">Cancelar Assinatura</h5>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Ao cancelar a cobrança desta assinatura, o seu acesso ao curso
                  será cancelado.
                </p>
                <b className="d-block text-center">
                  ATENÇÃO: Essa operação não poderá ser desfeita.
                </b>
                <p className="d-block mt-4 mb-4 text-center">
                  Tem certeza que deseja cancelar esta assinatura?
                </p>
                <div className="d-flex justify-content-between">
                  <Button variant="light">Agora não</Button>
                  <Button variant="danger">Cancelar Assinatura</Button>
                </div>
              </Modal.Body>
            </Modal>
            <main className="page-content">
              <section id="page-recurrences">
                <Row>
                  <Col>
                    <h2>Assinaturas</h2>
                    <div className="uk-card-default uk-card-body data-table">
                      <div className="top-bar">
                        <p className="text">
                          Aqui você administra as suas assinaturas ativas, ou
                          seja, as cobranças que são feitas de modo recorrente
                          em seu cartão de crédito ou pix.
                        </p>
                      </div>
                      <div className="table-responsive">
                        <table
                          className={
                            isLoading ? 'table' : 'table table-striped'
                          }
                        >
                          <thead>
                            <tr>
                              <th>
                                <div>
                                  <i className="bx bx-image-alt"></i>
                                  <span>Capa</span>
                                </div>
                              </th>
                              <th>
                                <div>
                                  <i className="bx bx-analyse"></i>
                                  <span>Assinatura</span>
                                </div>
                              </th>
                              <th>
                                <div>
                                  <i className="bx bx-tag"></i>
                                  <span>Autor</span>
                                </div>
                              </th>
                              <th>
                                <div>
                                  <i className="bx bx-credit-card"></i>
                                  <span>Método de pagamento</span>
                                </div>
                              </th>
                              <th>
                                <div>
                                  <i className="bx bx-dollar-circle"></i>
                                  <span>Valor</span>
                                </div>
                              </th>
                              <th>
                                <div>
                                  <i className="bx bx-calendar-week"></i>
                                  <span>Próx Cobrança</span>
                                </div>
                              </th>
                              <th>
                                <div>
                                  <i className="bx bx-flag"></i>
                                  <span>Status</span>
                                </div>
                              </th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {!isLoading &&
                              subscriptions.map((item) => (
                                <tr key={item.uuid}>
                                  <td>
                                    <img
                                      src={item.product.cover.cover}
                                      alt=""
                                      style={{ maxWidth: '50px' }}
                                    />
                                  </td>
                                  <td>{item.product.name}</td>
                                  <td>{item.author}</td>
                                  <td style={{ paddingLeft: 14 }}>
                                    {item.payment_method === 'pix'
                                      ? 'Pix'
                                      : 'Cartão de crédito'}
                                  </td>
                                  <td>
                                    {item.price.toLocaleString('pt-br', {
                                      style: 'currency',
                                      currency: 'BRL'
                                    })}
                                  </td>
                                  <td>{formatDate(item.next_charge)}</td>
                                  <td
                                    style={{
                                      width: 150,
                                      minWidth: 150
                                    }}
                                  >
                                    {item.active ? (
                                      <BadgeDataTable
                                        variant={'success'}
                                        name={'Ativo'}
                                      />
                                    ) : (
                                      <BadgeDataTable
                                        variant={'light'}
                                        name={'Desativado'}
                                      />
                                    )}
                                  </td>
                                  <td
                                    style={{
                                      width: 90,
                                      minWidth: 90,
                                      textAlign: 'center'
                                    }}
                                  >
                                    <Button
                                      size="xs"
                                      variant="primary"
                                      disabled={false}
                                      onClick={() => {
                                        setUuidSubscription(item.uuid)
                                        if (item.payment_method === 'pix') {
                                          setShowWarningModalPix(true)
                                        } else {
                                          setShowModal(true)
                                        }
                                      }}
                                      style={{
                                        padding: 5,
                                        borderRadius: 6,
                                        width: 30,
                                        height: 30
                                      }}
                                    >
                                      <i
                                        className="icon-feather-settings"
                                        style={{
                                          fontSize: 18
                                        }}
                                      />
                                    </Button>
                                  </td>
                                  <td
                                    style={{
                                      width: 90,
                                      minWidth: 90,
                                      textAlign: 'center'
                                    }}
                                  >
                                    <Button
                                      size="xs"
                                      variant="danger"
                                      disabled={false}
                                      onClick={() => {
                                        setUuidSubscription(item.uuid)
                                        setActiveSubscription(item)
                                        setShowCancelModal(true)
                                      }}
                                      style={{
                                        padding: 5,
                                        borderRadius: 6,
                                        width: 30,
                                        height: 30,
                                        backGroundColor: '#E9453A'
                                      }}
                                    >
                                      <i
                                        className="bx bx-trash"
                                        style={{ fontSize: 18 }}
                                      />
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            {subscriptions.length === 0 && !isLoading && (
                              <tr>
                                <td colSpan={100}>
                                  <div className="text-center my-4">
                                    Nenhuma assinatura encontrada...
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} className="mt-4">
                    <Pager
                      size={listingSize}
                      limit={listingLimit}
                      active={listingPage}
                      url={'/assinaturas'}
                    />
                  </Col>
                </Row>
                <Modal
                  show={showWarningModalPix}
                  onHide={() => setShowWarningModalPix(false)}
                  centered
                  id="modal-style"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Método de pagamento</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      Ao trocar seu método de pagamento na assinatura de
                      <br />
                      <b>Pix</b> para <b>Cartão de Crédito</b>, essa ação não
                      poderá ser desfeita.
                      <br />
                      Tem certeza que deseja alterar seu método de pagamento?
                    </p>
                    <Row
                      className="align-items-end justify-content-between"
                      style={{ padding: '21px 16px 4px 16px' }}
                    >
                      <div className="text-left">
                        <Button
                          size="xs"
                          variant="light"
                          className="align-right"
                          onClick={() => setShowWarningModalPix(false)}
                        >
                          Agora não
                        </Button>
                      </div>
                      <div className="text-right">
                        <Button
                          size="xs"
                          variant="success"
                          style={{ backgroundColor: '#00A94F' }}
                          onClick={() => {
                            setShowWarningModalPix(false)
                            setShowModal(true)
                          }}
                        >
                          Alterar agora
                        </Button>
                      </div>
                    </Row>
                  </Modal.Body>
                </Modal>
                {showModal && (
                  <ModalChangeCard
                    showModal={showModal}
                    setShowModal={setShowModal}
                    uuidSubscription={uuidSubscription}
                    fetchData={fetchData}
                  />
                )}
                {showCancelModal && (
                  <ModalCancel
                    showCancelModal={showCancelModal}
                    setShowCancelModal={setShowCancelModal}
                    uuidSubscription={uuidSubscription}
                    fetchData={fetchData}
                    activeSubscription={activeSubscription}
                  />
                )}
              </section>
            </main>
          </Container>
        </div>
      </div>
    </>
  )
}

export default PageSubscriptions
