import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo-horizontal-light.png'
import img404 from '../../assets/images/img-404.svg'
import './style.scss'

const Error404 = () => {
  return (
    <div className='bg-404'>
      <div className='container'>
        <div className='row'>
          <div className='left col-lg-6 col-md-9'>
            <img src={logo} />
            <h1>A página que você está procurando não foi encontrada!</h1>
            <p>
              Você pode ter digitado o endereço incorretamente ou a página pode
              ter sido movida.
            </p>
            <Link className='btn btn-primary' to='/'>
              Voltar para a página principal
            </Link>
          </div>
          <div className='right col-lg-6 col-md-9'>
            <img src={img404} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error404
