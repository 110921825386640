/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-expressions */
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../providers/api'
import PageCourses from '../courses'
import PageEbooks from '../ebooks'
import './style.scss'
import { useProducts } from '../../providers/contextProducts'
import moment from 'moment'

const compareCoursesContentAreDifferent = (newArr, oldArr) => {
  if (newArr.length !== oldArr.length) return true
  let response = false
  for (let index = 0; index < newArr.length; index++) {
    if (newArr[index].uuid !== oldArr[index].uuid) {
      response = true
    }
  }
  return response
}

function PageHome() {
  const { page } = useParams()

  const { coursesContext, coursesViewedContext, ebooksContext } = useProducts()
  const { coursesValue, setCoursesValue } = coursesContext
  const { coursesViewedValue, setCoursesViewedValue } = coursesViewedContext
  const { ebooksValue, setEbooksValue } = ebooksContext

  useEffect(() => {
    if (coursesValue.length === 0 || ebooksValue.length === 0) {
      api
        .get(`products/ebooks`)
        .then((response) => {
          setEbooksValue(response.data.rows)
        })
        .catch((err) => {
          // err.data
        })

      api
        .get(`products/courses`)
        .then((response) => {
          const content = response.data.rows
            .filter((item) => item.last_viewed_date)
            .sort((a, b) => {
              moment(b.last_viewed_date).valueOf() -
                moment(a.last_viewed_date).valueOf()
            })
          setCoursesValue(response.data.rows)
          setCoursesViewedValue(content)
        })
        .catch((err) => {
          // err.data
        })
    } else {
      api
        .get(`products/courses`)
        .then((response) => {
          const content = response.data.rows
            .filter((item) => item.last_viewed_date)
            .sort(
              (a, b) =>
                moment(b.last_viewed_date).valueOf() -
                moment(a.last_viewed_date).valueOf()
            )
          if (compareCoursesContentAreDifferent(content, coursesViewedValue)) {
            setCoursesViewedValue(content)
          }
        })
        .catch((err) => {
          // err.data
        })
    }
  }, [page])

  return (
    <>
      <main id="page-home" className="wrap-page-content">
        <PageCourses
          courses={coursesViewedValue}
          title={'Continuar assistindo'}
        />
        <PageCourses courses={coursesValue} title={'Meus cursos'} />
        <PageEbooks ebooks={ebooksValue} />
        {coursesValue.length === 0 && ebooksValue.length === 0 && (
          <div className="products container">
            <h3>Não há conteúdo para mostrar</h3>
          </div>
        )}
      </main>
    </>
  )
}

export default PageHome
