import React from 'react'

const WhatsApp = ({ phone, message }) => {
  let link = `https://wa.me/${phone}`
  if (message) {
    link = `${link}?text=${message}`
  }
  return (
    phone && (
      <a id="whatsapp-element" href={link} target="_blank">
        <i class="bx bxl-whatsapp"></i>
      </a>
    )
  )
}

export default WhatsApp
