import { useState } from 'react'
import { Row, Col, Modal, Button } from 'react-bootstrap'
import api from '../../providers/api'
import { notify } from '../../components/functions'
import formatDate from '../../utils/formatter'

const ModalCancel = ({
  showCancelModal,
  setShowCancelModal,
  uuidSubscription,
  fetchData,
  activeSubscription
}) => {
  const [confirm, setConfirm] = useState(false)
  const [requesting, setRequesting] = useState(false)

  const cancelSubscription = () => {
    setRequesting(true)
    api
      .post(`subscriptions/${uuidSubscription}/cancel`)
      .then((response) => {
        notify({
          message: 'Assinatura cancelada com sucesso',
          type: 'success'
        })
        fetchData()
        setShowCancelModal(false)
      })
      .catch((err) => {
        notify({ message: 'Erro ao cancelar assinatura', type: 'error' })
      })
      .finally(() => {
        setRequesting(false)
      })
  }

  return (
    <>
      <Modal
        show={showCancelModal}
        onHide={() => setShowCancelModal(false)}
        centered
        id="modal-style"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmar cancelamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Se você confirmar e encerrar sua assinatura agora, ainda poderá
            acessá-la até {formatDate(activeSubscription.next_charge)}.
          </p>
          <Row
            className="align-items-end justify-content-between"
            style={{ padding: '21px 16px 4px 16px' }}
          >
            <div className="text-left">
              <Button
                size="xs"
                variant="light"
                className="align-right"
                onClick={() => setShowCancelModal(false)}
              >
                Agora não
              </Button>
            </div>
            <div className="text-right">
              {!confirm ? (
                <Button
                  size="xs"
                  variant="default"
                  onClick={() => {
                    setConfirm(true)
                  }}
                >
                  Cancelar Assinatura
                </Button>
              ) : (
                <Button
                  size="xs"
                  variant="danger"
                  disabled={requesting}
                  onClick={cancelSubscription}
                >
                  {!requesting
                    ? 'Confirmar Cancelamento'
                    : 'Cancelando Assinatura...'}
                </Button>
              )}
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalCancel
